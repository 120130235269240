import React, {useContext, useEffect, useState} from 'react';

import {AccountSelectionView} from './login/AccountSelectionView';
import {FindUserOrganizationView} from './login/FindUserOrganizationView';
import {AuthContext} from '../../../auth';
import {Auth0ContextInterface} from '@auth0/auth0-react';
import {AuthPayloadDTO, authRegionMapping, Login, STALogin} from '../../../types';
import {
  CURRENT_SELECTED_ORGANIZATION,
  CURRENT_SELECTED_USER,
  DEBUG,
  DESIRED_ROUTE,
  EMAIL,
  ENCODED_DATA_FROM_MOBILE_CLIENTS,
  HYPERCARE_REGION,
  INTRO_DONE,
  MOBILE_CLIENT_ID,
  MOBILE_DEVICE_ID,
  ORGANIZATION_ACCOUNTS_DATA,
  REDIRECT_URI,
  SELECTED_ACCOUNT,
  VIEW,
} from '../../../constants/storageKeys';
import HypercareLogoSVG from '../../../svgs/HypercareLogoSVG';
import {CurrentSelectedOrganization} from '../../../microfrontend/types/login.types';
import {LoginEmailAddressView} from '../../../microfrontend/login/LoginEmailAddressView';
import {LoginPasswordView} from '../../../microfrontend/login/LoginPasswordView';
import {OrganizationViewModel} from './view-models/OrganizationViewModel';
import {
  BasicUser,
  CurrentLoginStep,
  ErrorModalButtons,
  OrganizationAccountsCacheData,
  OrganizationAccountsCacheDataFromMobile,
  OrganizationAccountsDataFromAdmin,
  OrgLoginMethods,
  OrgViews,
  WebViewCallBacks,
} from '../../../types/sta';
import {LoginOTPView} from '../../../microfrontend/login/LoginOTPView';
import {LoginPageHypercareLogoContainer, PageContainer} from '../styled/login.styled';
import {AccountDiscoveryCoordinator} from './accountDiscovery/AccountDiscoveryCoordinator';
import ChangeRegionModal from '../../../components/ChangeRegion/ChangeRegionModal';
import {LOGIN} from '../../../constants/strings';
import HypercareAuthRegionContext from '../../../contexts/HypercareLoginCoordinatorContext';
import {localStorageService} from '../../../services/localStorageService';
import {AccountDiscoveryViewModel} from './view-models/AccountDiscoveryViewModel';
import {AUTH_INTRO} from '../../../constants/routerPathName';
import {toast} from 'react-toastify';
import {AuthViewModel} from '../../../auth/AuthViewModel';
import {
  decodeBase64String,
  isAccessTokenValid,
  isRefreshTokenValid,
  sortSavedAccounts,
} from '../../../utils/sta/staUtils';

import {STEP} from '../../../constants/queryParams';
import {Redirect} from 'react-router-dom';

import {getParsedAuthRegion} from '../../../utils/localStorageHandler';
import {useDispatch} from 'react-redux';
import {actions} from '../../../redux/actions/authRegion';
import {buttonClasses, CircularProgress} from '@mui/material';
import {ErrorModal} from './components/ErrorModal';
import HypercareErrorModalContext from '../../../contexts/ErrorModalContext';
import {
  CONTACT_ADMIN_FOR_HELP,
  NO_SUPPORTED_LOGIN_METHODS,
  NO_SUPPORTED_LOGIN_METHODS_DESCRIPTION,
  NO_SUPPORTED_LOGIN_METHODS_TITLE,
  USE_CORPORATE_EMAIL,
  USER_BLOCKED,
} from '../../../constants/login';
import {TOO_MANY_CHALLENGES} from '../../../constants/networkError';
import {callNative} from '../../../nativeBridge';

interface ILoginPageCoordinatorProps {
  isLoggedIn: boolean;
  auth0props: Auth0ContextInterface;
  login: Login;
  STALogin: STALogin;
}

const LoginPageCoordinator = ({isLoggedIn, auth0props, STALogin}: ILoginPageCoordinatorProps) => {
  const decodedURI = decodeURIComponent(window.location.search);
  const params = new URLSearchParams(decodedURI);

  const stepFromParams = parseInt(params.get(STEP) || '');
  const redirectUriFromQueryParam = params.get(REDIRECT_URI);
  const selectedAccountForLogin = params.get(SELECTED_ACCOUNT);
  const viewFromParams = params.get(VIEW);
  const dispatch = useDispatch();

  const [load, setLoad] = useState(!!selectedAccountForLogin);

  const [currentStep, setCurrentStep] = useState<CurrentLoginStep>(
    stepFromParams ? stepFromParams : CurrentLoginStep.STEP_1,
  );
  const [currentSelectedOrg, setCurrentSelectedOrg] = useState<CurrentSelectedOrganization>({
    loginMethods: [],
    imageURL: 'https://hypercare.github.io/public-uploads-prod/organization_logos/hypercare_icon_v2-01.png',
    url: '',
    name: '',
    id: 0,
  });

  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [OTPError, setOTPError] = useState('');

  const [isResendingOTP, setIsResendingOTP] = useState(false);
  const [challengeId, setChallengeId] = useState('');
  const [currentEmail, setCurrentEmail] = useState('');

  const [isPasswordLoading, setIsPasswordLoading] = useState(false);
  const [isOTPLoading, setIsOTPLoading] = useState(false);
  const [isEmailLoading, setIsEmailLoading] = useState(false);
  const [isLoginMethodSwitchable, setIsLoginMethodSwitchable] = useState(false);
  const [showLogoutOrContinueModal, setShowLogoutOrContinueModal] = useState(false);
  const {changeRegionModalOpenStatus, setChangeRegionModalOpenStatus} = useContext(HypercareAuthRegionContext);
  const {errorModalOpenStatus, setErrorModalOpenStatus, errorModalDetails, setErrorModalDetails} =
    useContext(HypercareErrorModalContext);
  const currentCacheData = localStorageService.getItem<OrganizationAccountsCacheData>(ORGANIZATION_ACCOUNTS_DATA);
  const [savedAccountsData, setSavedAccountsData] = useState<AuthPayloadDTO[]>(
    sortSavedAccounts(currentCacheData?.savedOrganizations || []) ?? [],
  );
  const [errorModalButtons, setErrorModalButtons] = useState<ErrorModalButtons[] | null>(null);

  const {submitEmailAddress, submitOTP, submitPassword, handleOTPLogin, redirectUserToSignup} = OrganizationViewModel();
  const {handleSaveAccountsQueryParamToCache, handleResendOTP} = AccountDiscoveryViewModel();
  const {handleRefreshAccessToken} = AuthViewModel();
  const currentRegion = getParsedAuthRegion();

  const encodedDataFromMobile = localStorageService.getItem<OrganizationAccountsCacheDataFromMobile>(
    ENCODED_DATA_FROM_MOBILE_CLIENTS,
  );
  const currentSelectedOrgFromLS =
    localStorageService.getItem<CurrentSelectedOrganization>(CURRENT_SELECTED_ORGANIZATION);

  const currentSelectedEmailFromLS = localStorageService.getItem<'string'>(EMAIL);

  const handleShowAccountDiscoveryFlow = () => {
    setCurrentStep(CurrentLoginStep.STEP_6);
  };

  if (redirectUriFromQueryParam) {
    window.localStorage.setItem(REDIRECT_URI, redirectUriFromQueryParam);
  } else {
    window.localStorage.removeItem(REDIRECT_URI);
  }

  useEffect(() => {
    const getData = async (selectedAccountForLogin) => {
      localStorage.setItem(INTRO_DONE, '1');
      const {clientId, selectedAccount, regionCode, deviceId}: OrganizationAccountsDataFromAdmin =
        decodeBase64String(selectedAccountForLogin);

      if (clientId) {
        localStorageService.setItem(MOBILE_CLIENT_ID, clientId);
      }

      if (deviceId) {
        localStorageService.setItem(MOBILE_DEVICE_ID, deviceId);
      }

      if (regionCode) {
        let overrideRegion;
        if (regionCode === 'US') {
          overrideRegion = 'USA';
        } else if (regionCode === 'CA') {
          overrideRegion = 'CA';
        } else if (regionCode === 'EU') {
          overrideRegion = 'EU';
        }
        if (overrideRegion !== currentRegion) {
          localStorage.removeItem(ORGANIZATION_ACCOUNTS_DATA);
          setSavedAccountsData([]);
        }
        window.localStorage.setItem(HYPERCARE_REGION, overrideRegion);
        dispatch(actions.setHypercareRegion(overrideRegion));
      }

      if (selectedAccount) {
        if (!currentCacheData?.savedOrganizations?.length) {
          setIsEmailLoading(true);

          setCurrentSelectedOrg(selectedAccount.organization);
          setCurrentEmail(selectedAccount.user.email || '');

          const res = await submitEmailAddress(selectedAccount.user.email || '', selectedAccount.organization);

          if (res && 'error' in res) {
            setEmailError(res.error || 'Error occurred when submitting email address');
          }
          if (res && 'auth0Id' in res) {
            loginWithAuth0(res.auth0Id);
          }
          setIsEmailLoading(false);
          setLoad(false);

          if (res && 'screen' in res && res.screen) {
            switch (res.screen) {
              case OrgLoginMethods.OTP:
                setCurrentStep(CurrentLoginStep.STEP_5);
                'challengeId' in res && res.challengeId && setChallengeId(res.challengeId);
                'isMethodSwitchable' in res && res.isMethodSwitchable && setIsLoginMethodSwitchable(true);
                return;
              case OrgLoginMethods.PASSWORD:
                setCurrentStep(CurrentLoginStep.STEP_4);
                'isMethodSwitchable' in res && res.isMethodSwitchable && setIsLoginMethodSwitchable(true);
                return;
              case OrgLoginMethods.LOGIN:
                'data' in res && STALogin(currentSelectedOrg, res.data, currentEmail);
                break;
              default:
                break;
            }
          }
        } else {
          setLoad(false);
        }
      }
    };

    if (selectedAccountForLogin) {
      getData(selectedAccountForLogin);
    } else {
      setLoad(false);
    }
  }, []);

  useEffect(() => {
    if (!savedAccountsData?.length || currentCacheData?.savedOrganizations.length === 0 || stepFromParams) {
      handleShowAccountDiscoveryFlow();
    } else {
      setCurrentStep(CurrentLoginStep.STEP_1);
    }
  }, [savedAccountsData, changeRegionModalOpenStatus]);

  useEffect(() => {
    if (viewFromParams && viewFromParams === OrgViews.PASSWORD) {
      if (currentSelectedEmailFromLS) setCurrentEmail(currentSelectedEmailFromLS);
      if (currentSelectedOrgFromLS) setCurrentSelectedOrg(currentSelectedOrgFromLS);
      setCurrentStep(CurrentLoginStep.STEP_4);
    }
  }, []);

  useEffect(() => {
    if (encodedDataFromMobile) {
      const {clientId, regionCode, deviceId, selectedAccountUserId, selectedOpenDoorOrganization} =
        encodedDataFromMobile;

      handleSaveAccountsQueryParamToCache(encodedDataFromMobile);
      localStorage.setItem(INTRO_DONE, '1');
      encodedDataFromMobile.clientId && localStorageService.setItem(MOBILE_CLIENT_ID, clientId);
      encodedDataFromMobile.deviceId && localStorageService.setItem(MOBILE_DEVICE_ID, deviceId);

      const region = authRegionMapping[regionCode];
      if (region) {
        localStorage.setItem(HYPERCARE_REGION, region);
        dispatch(actions.setHypercareRegion(region));
      }

      !selectedAccountUserId &&
        !selectedOpenDoorOrganization?.email &&
        callNative(WebViewCallBacks.ON_WEB_VIEW_READY, {screen: OrgViews.EMAIL});
    }

    if (
      window.localStorage.getItem(INTRO_DONE) !== '1' &&
      !redirectUriFromQueryParam?.includes('admin') &&
      !redirectUriFromQueryParam?.includes('4000')
    ) {
      window.routerHistory.push(`/${AUTH_INTRO}`);
    }
  }, []);

  useEffect(() => {
    if (
      currentCacheData &&
      currentCacheData?.savedOrganizations?.length >= 1 &&
      currentCacheData?.selectedAccountUserId
    ) {
      const findAccount = currentCacheData.savedOrganizations.find(
        (acc) => acc.user.id === currentCacheData.selectedAccountUserId,
      );

      if (findAccount && !redirectUriFromQueryParam)
        STALogin(findAccount.organization, findAccount, findAccount.user.email || '');
    }
  }, []);

  useEffect(() => {
    const refreshTokenFlow = async () => {
      if (currentCacheData?.savedOrganizations && currentCacheData?.savedOrganizations?.length > 0) {
        for (const acc of currentCacheData.savedOrganizations) {
          if (!isAccessTokenValid(acc) && isRefreshTokenValid(acc)) {
            await handleRefreshAccessToken(acc.refreshToken || '', acc.user.id);
          }
        }
      }
    };

    refreshTokenFlow();
  }, []);

  if (isLoggedIn && !redirectUriFromQueryParam?.includes('admin') && !redirectUriFromQueryParam?.includes('4000')) {
    const desiredRoute = sessionStorage.getItem(DESIRED_ROUTE);
    return <Redirect to={desiredRoute ? desiredRoute : '/messenger/home'} />;
  }

  const handleGoToPreviousStep = () => {
    setEmailError('');
    setPasswordError('');
    setIsEmailLoading(false);
    setIsPasswordLoading(false);
    setIsLoginMethodSwitchable(false);

    if (
      !savedAccountsData?.length &&
      currentCacheData?.savedOrganizations &&
      currentCacheData?.savedOrganizations?.length > 0
    ) {
      setCurrentStep(CurrentLoginStep.STEP_1);
    } else {
      setCurrentStep(CurrentLoginStep.STEP_6);
    }
  };

  const handleFindMyOrgBackButtonClick = () => {
    setCurrentStep(CurrentLoginStep.STEP_6);
  };

  const handleEmailBackButtonClick = () => {
    setEmailError('');
    setIsEmailLoading(false);
    setIsLoginMethodSwitchable(false);
    setCurrentStep(CurrentLoginStep.STEP_2);
  };

  const showDebugMenu = () => {
    window.routerHistory.replace(`/${DEBUG}`);
  };

  const handleSubmitPassword = async (value: string) => {
    setIsPasswordLoading(true);
    const res = await submitPassword(currentSelectedOrg.url || '', currentEmail, value);

    if (res?.error) {
      setPasswordError(res.error);
      setIsPasswordLoading(false);
    }
    if (res?.data) {
      STALogin(currentSelectedOrg, res.data, currentEmail);
    }
  };

  const routeToSignup = async (email: string) => {
    const res = await redirectUserToSignup(email);
    if (res && res?.error) {
      setErrorModalDetails({
        title: 'Too many verification codes sent',
        description: 'Please try again later.',
      });
      setErrorModalButtons([]);
    } else {
      setErrorModalOpenStatus(false);
    }
  };

  const handleSubmitEmailAddress = async (value: string) => {
    console.log('hey');

    setIsEmailLoading(true);
    setCurrentEmail(value);
    setErrorModalButtons(null);

    const res = await submitEmailAddress(value.toLowerCase(), currentSelectedOrg);

    if (res && 'error' in res) {
      let errorTitle = '';
      let errorDescription = '';
      let buttons: ErrorModalButtons[] | null = null;

      switch (res.error) {
        case USER_BLOCKED:
          errorTitle = 'Your email is linked to a removed account';
          errorDescription = `Contact your admin if this was a mistake.`;
          break;

        case NO_SUPPORTED_LOGIN_METHODS:
          errorTitle = NO_SUPPORTED_LOGIN_METHODS_TITLE;
          errorDescription = NO_SUPPORTED_LOGIN_METHODS_DESCRIPTION;
          break;

        case USE_CORPORATE_EMAIL:
          errorTitle = 'Please use your work email to login to this organization ';
          errorDescription = CONTACT_ADMIN_FOR_HELP;
          break;

        case 'NoMatchedAccount':
          errorTitle = 'There’s no account associated with this email. Would you like to sign up instead?';
          errorDescription = `Contact your admin if this was a mistake.`;
          buttons = [
            {
              type: 'secondary',
              buttonLabel: 'Close',
              onClickHandler: () => setErrorModalOpenStatus(false),
              id: 'close-btn',
            },
            {
              type: 'primary',
              buttonLabel: 'Sign Up',
              onClickHandler: () => routeToSignup(value),
              id: 'signup-btn',
            },
          ];
          break;

        case TOO_MANY_CHALLENGES:
          errorTitle = 'Too many verification codes sent';
          errorDescription = 'Please try again later.';
          buttons = [
            {
              type: 'secondary',
              buttonLabel: 'Contact Hypercare Support',
              onClickHandler: () => window.open('https://hypercare.com/contact', '_blank', 'noopener noreferrer'),
              id: 'close-btn',
            },
          ];
          break;

        default:
          errorTitle = 'There’s no account associated with this email. Would you like to sign up instead?';
          errorDescription = `Contact your admin if this was a mistake.`;
          buttons = [
            {
              type: 'secondary',
              buttonLabel: 'Close',
              onClickHandler: () => setErrorModalOpenStatus(false),
              id: 'close-btn',
            },
            {
              type: 'primary',
              buttonLabel: 'Sign Up',
              onClickHandler: () => routeToSignup(value),
              id: 'signup-btn',
            },
          ];
          break;
      }

      setErrorModalDetails({
        title: errorTitle,
        description: errorDescription,
      });
      if (buttons && buttons?.length >= 1) {
        setErrorModalButtons(buttons);
      }
      setErrorModalOpenStatus(true);
      setEmailError(errorTitle || 'Error occurred when submitting email address');
    }
    if (res && 'auth0Id' in res) {
      loginWithAuth0(res.auth0Id);
    }
    setIsEmailLoading(false);

    if (res && 'screen' in res && res.screen) {
      switch (res.screen) {
        case OrgLoginMethods.OTP:
          setCurrentStep(CurrentLoginStep.STEP_5);
          'challengeId' in res && res.challengeId && setChallengeId(res.challengeId);
          'isMethodSwitchable' in res && res.isMethodSwitchable && setIsLoginMethodSwitchable(true);
          return;
        case OrgLoginMethods.PASSWORD:
          setCurrentStep(CurrentLoginStep.STEP_4);
          'isMethodSwitchable' in res && res.isMethodSwitchable && setIsLoginMethodSwitchable(true);
          return;
        case OrgLoginMethods.LOGIN:
          'data' in res && STALogin(currentSelectedOrg, res.data, currentEmail);
          break;
        default:
          break;
      }
    }

    setEmailError('');
  };

  const loginWithAuth0 = (auth0Id: string) => {
    auth0props.loginWithRedirect({
      connection: auth0Id,
      scope: 'openid profile email',
    });
  };

  const handleSubmitOTP = async (value: string) => {
    setIsOTPLoading(true);
    const res = await submitOTP(challengeId, value, currentSelectedOrg.url || '');

    if (res?.error || 'error' in res) {
      if (res.error === 'MaxAddressVerifyAttempts') {
        let description = 'Please try again later';
        let buttons = [
          {
            type: 'secondary',
            buttonLabel: 'Contact Hypercare Support ',
            onClickHandler: () => {
              window.open('https://hypercare.com/contact', '_blank', 'noopener noreferrer');
              setErrorModalOpenStatus(false);
            },
            id: 'close-btn',
          },
        ];

        setErrorModalDetails({
          title: 'Too many failed verification attempts',
          description: description,
        });

        setErrorModalButtons(buttons);

        setErrorModalOpenStatus(true);
      }

      setOTPError(res.error || 'Error occurred when submitting otp');
    }

    if (res.data) {
      STALogin(currentSelectedOrg, res.data, currentEmail);
    }
    setIsOTPLoading(false);
  };

  const resendOTPCode = async (email: string) => {
    const res = await handleResendOTP(email);

    if (res?.challengeId) {
      setChallengeId(res.challengeId);
      toast.success(`Successfully resent verification code`);
    }

    if (res?.error) {
      toast.error('Unable to resend verification code');
    }
  };

  const handleIsLoginMethodSwitchableClick = async (type: string) => {
    const currentUser = localStorageService.getItem<BasicUser>('currentSelectedUser');
    console.log(currentUser);

    if (type !== 'password') {
      setCurrentStep(CurrentLoginStep.STEP_4);
    } else {
      const ers = await handleOTPLogin(currentEmail);
      console.log(ers);

      if ('error' in ers) {
        let errorTitle = '';
        let errorDescription = '';
        let buttons: ErrorModalButtons[] | null = null;
        errorTitle = 'Too many verification codes sent';

        if (currentUser && currentUser?.loginMethods?.includes('password')) {
          errorDescription = 'Login with password instead or try again later ';
          buttons = [
            {
              type: 'secondary',
              buttonLabel: 'Contact Hypercare Support ',
              onClickHandler: () => {
                window.open('https://hypercare.com/contact', '_blank', 'noopener noreferrer');
                setErrorModalOpenStatus(false);
              },
              id: 'close-btn',
            },
            {
              type: 'primary',
              buttonLabel: 'login with Password',
              onClickHandler: () => {
                setCurrentStep(CurrentLoginStep.STEP_2);
                setIsLoginMethodSwitchable(false);
                setErrorModalOpenStatus(false);
              },
              id: 'signup-btn',
            },
          ];
        } else {
          errorDescription = 'Please try again later.';
          buttons = [
            {
              type: 'secondary',
              buttonLabel: 'Contact Hypercare Support ',
              onClickHandler: () => {
                window.open('https://hypercare.com/contact', '_blank', 'noopener noreferrer');
                setErrorModalOpenStatus(false);
              },
              id: 'close-btn',
            },
          ];
        }

        setErrorModalDetails({
          title: errorTitle,
          description: errorDescription,
        });
        setErrorModalOpenStatus(true);

        setErrorModalButtons(buttons);
        setCurrentStep(CurrentLoginStep.STEP_4);
        return;
      }

      if ('challengeId' in ers) {
        setChallengeId(ers.challengeId);
      }
      setCurrentStep(CurrentLoginStep.STEP_5);
    }
  };

  const handleForgotPasswordClick = () => {
    const params = new URLSearchParams({
      email: currentEmail,
      orgUrl: currentSelectedOrg.url,
      orgName: currentSelectedOrg.name,
    });
    window.routerHistory.push(`/resetPassword?${params.toString()}`);
  };

  const showEmailAddressView = () => {
    return (
      <LoginEmailAddressView
        handleGoBack={handleEmailBackButtonClick}
        handleNext={handleSubmitEmailAddress}
        organization={currentSelectedOrg}
        error={emailError}
        isLoading={isEmailLoading}
        isLoginMethodSwitchable={isLoginMethodSwitchable}
        handleIsLoginMethodSwitchableClick={handleIsLoginMethodSwitchableClick}
      />
    );
  };

  const showPasswordView = () => {
    return (
      <LoginPasswordView
        handleGoBack={handleGoToPreviousStep}
        handleNext={handleSubmitPassword}
        organization={currentSelectedOrg}
        error={passwordError}
        isLoading={isPasswordLoading}
        isLoginMethodSwitchable={isLoginMethodSwitchable}
        handleIsLoginMethodSwitchableClick={handleIsLoginMethodSwitchableClick}
        handleForgotPassword={handleForgotPasswordClick}
      />
    );
  };

  const showOTPView = () => {
    return (
      <LoginOTPView
        handleGoBack={handleGoToPreviousStep}
        handleNext={handleSubmitOTP}
        organization={currentSelectedOrg}
        resend={resendOTPCode}
        isResending={isResendingOTP}
        error={OTPError}
        currentSelectedEmail={currentEmail}
        isLoading={isOTPLoading}
        isLoginMethodSwitchable={isLoginMethodSwitchable}
        handleIsLoginMethodSwitchableClick={handleIsLoginMethodSwitchableClick}
      />
    );
  };

  const handleShowEmailView = () => {
    setCurrentStep(CurrentLoginStep.STEP_3);
  };
  const handleShowPasswordView = (isMethodSwitchable?: boolean) => {
    setCurrentStep(CurrentLoginStep.STEP_4);
    isMethodSwitchable && setIsLoginMethodSwitchable(true);
  };

  const handleShowOtpView = (isMethodSwitchable?: boolean) => {
    setCurrentStep(CurrentLoginStep.STEP_5);
    isMethodSwitchable && setIsLoginMethodSwitchable(true);
  };

  const showAccountDiscovery = () => {
    return (
      <AccountDiscoveryCoordinator
        showFindMyOrgView={() => setCurrentStep(CurrentLoginStep.STEP_2)}
        setErrorModalOpenStatus={setErrorModalOpenStatus}
        setErrorModalDetails={setErrorModalDetails}
        setErrorModalButtons={setErrorModalButtons}
      />
    );
  };

  const renderCurrentStep = (currentStep: CurrentLoginStep) => {
    switch (currentStep) {
      case CurrentLoginStep.STEP_1:
        return (
          <AccountSelectionView
            handleNextStep={handleShowAccountDiscoveryFlow}
            STALogin={STALogin}
            showOtpView={handleShowOtpView}
            showPasswordView={handleShowPasswordView}
            loginWithAuth0={loginWithAuth0}
            setEmail={setCurrentEmail}
            setCurrentChallengeId={setChallengeId}
            setCurrentSelectedOrg={setCurrentSelectedOrg}
            savedAccountsData={savedAccountsData}
            setSavedAccountsData={setSavedAccountsData}
            handleShowEmailView={handleShowEmailView}
          />
        );
      case CurrentLoginStep.STEP_2:
        return (
          <FindUserOrganizationView
            handleGoBack={handleFindMyOrgBackButtonClick}
            setCurrentSelectedOrg={setCurrentSelectedOrg}
            handleShowEmailView={handleShowEmailView}
            handleShowPasswordView={handleShowPasswordView}
          />
        );
      case CurrentLoginStep.STEP_3:
        return showEmailAddressView();
      case CurrentLoginStep.STEP_4:
        return showPasswordView();
      case CurrentLoginStep.STEP_5:
        return showOTPView();
      case CurrentLoginStep.STEP_6:
        return showAccountDiscovery();

      default:
        return (
          <AccountSelectionView
            savedAccountsData={savedAccountsData}
            handleNextStep={handleShowAccountDiscoveryFlow}
            STALogin={STALogin}
            showOtpView={handleShowOtpView}
            showPasswordView={handleShowPasswordView}
            loginWithAuth0={loginWithAuth0}
            setCurrentChallengeId={setChallengeId}
            setEmail={setCurrentEmail}
            setCurrentSelectedOrg={setCurrentSelectedOrg}
            setSavedAccountsData={setSavedAccountsData}
            handleShowEmailView={handleShowEmailView}
          />
        );
    }
  };

  if (load) {
    return (
      <PageContainer>
        <CircularProgress />
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <LoginPageHypercareLogoContainer onClick={showDebugMenu}>
        <HypercareLogoSVG />
      </LoginPageHypercareLogoContainer>

      {renderCurrentStep(currentStep)}
      {changeRegionModalOpenStatus && (
        <ChangeRegionModal
          isOpen={changeRegionModalOpenStatus}
          setIsOpen={setChangeRegionModalOpenStatus}
          currentScreen={LOGIN}
          setSavedAccountsData={setSavedAccountsData}
        />
      )}

      {errorModalOpenStatus && (
        <ErrorModal
          title={errorModalDetails.title}
          description={errorModalDetails.description}
          isOpen={errorModalOpenStatus}
          setIsOpen={setErrorModalOpenStatus}
          buttons={errorModalButtons}
        />
      )}
    </PageContainer>
  );
};

export const HypercareLoginPageCoordinator = () => {
  return (
    <AuthContext.Consumer>
      {({isLoggedIn, auth0props, login, STALogin}) => (
        <LoginPageCoordinator STALogin={STALogin} isLoggedIn={isLoggedIn} auth0props={auth0props} login={login} />
      )}
    </AuthContext.Consumer>
  );
};
