import {createAction, ActionType, getType} from 'typesafe-actions';
import hypercareOnCallReducer from './onCall';
import {Organization} from '../../microfrontend/types/login.types';
import {Action} from '@fullcalendar/react';
import {Actions} from '../reducers';
import {AuthPayloadDTO, Client} from '../../types';

export const actions = {
  setCurrentAccount: createAction('currentAccount/SET_CURRENT_ACCOUNT', (resolve) => (account) => resolve({account})),
};

export type CurrentAccountActions = ActionType<typeof actions>;

export interface CurrentAccountState extends AuthPayloadDTO {}

export const initialState: CurrentAccountState = {
  accessToken: '',
  accessTokenExpiresAt: '',
  organization: {url: '', name: '', logo: '', loginMethods: [], imageURL: '', id: 0},
  refreshTokenExpiresAt: '',
  scopeToken: '',
  user: {
    email: '',
    firstname: '',
    id: '',
    lastname: '',
    username: '',
    isAdmin: false,
    loginMethods: [],
  },
};

const currentAccountReducer = (state: CurrentAccountState = initialState, action: Actions) => {
  switch (action.type) {
    case getType(actions.setCurrentAccount):
      const newState = {...action.payload.account};
      return {...newState};
    default:
      return state;
  }
};

export default currentAccountReducer;
