import gql from 'graphql-tag';

export type GetOrganizationByNameResponse = {
  data: {
    findOrganizationByName: {
      __typename: string;
      name: string;
      id: number;
      url: string;
    };
  };
};

export const GET_ORGANIZATION_BY_NAME = gql`
  query GetOrganizationByName($name: String!) {
    findOrganizationByName(name: $name) {
      ...FindOrganizationResultFragment
    }
  }
  fragment FindOrganizationResultFragment on FindOrganizationResult {
    __typename
    ... on Error {
      __typename
      message
    }
    ...OrganizationFragment
  }

  fragment OrganizationFragment on Organization {
    __typename
    id
    name
    url
  }
`;
