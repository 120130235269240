import {Auth0ContextInterface} from '@auth0/auth0-react';
import {User} from 'src/types/User';
import {Organization} from '../microfrontend/types/login.types';

export type Login = (username: string, password: string) => Promise<LoginResponse> | null;

export type LoginViaSSO = (token: string, provider: AuthSSOVendors) => Promise<LoginResponse> | null;
export type StaSSOLogin = (
  token: string,
  provider: AuthSSOVendors,
  orgUrl: string,
  email: string,
) => Promise<LoginResponse> | null;
export type STALogin = (currentOrg: Organization, payload: AuthPayloadDTO, email: string) => Promise<string | null>;

export type AuthRegion = 'CA' | 'USA' | 'EU';

export type AuthRegionName = 'Canada' | 'United States' | 'Europe';

export interface IAuthRegionData {
  region: AuthRegion;
  regionName: AuthRegionName;
  flagIcon: any;
}

export const authRegionMapping = {
  US: 'USA',
  CA: 'CA',
  EU: 'EU',
};

export interface AuthProviderState {
  isLoggedIn: boolean;
  login: Login;
  loginViaSSO: LoginViaSSO;
  staSSOLogin: StaSSOLogin;
  STALogin: STALogin;
  logout: () => void;
  setAuthRegion: (region: AuthRegion) => void;
  authInfo: AuthPayload;
  authRegion: AuthRegion;
  authCloneForOnboarding: AuthPayload | null;
  auth0props: Auth0ContextInterface;
  updateAuthUserInfo: (user: Pick<User, 'id' | 'firstname' | 'username' | 'lastname'>) => void;
}

export interface VerificationModalProps {
  accessToken?: string;
  emailAddress: string;
  phoneAddress: string;
  addressType: 'email' | 'phoneNumber';
}

export interface LoginResponse {
  data?: AuthPayload;
  success: boolean;
  error?: string;
}

export interface AuthPayload {
  accessToken?: string;
  accessTokenExpiresAt?: string;
  client?: Client;
  refreshToken?: string;
  refreshTokenExpiresAt?: string;
  scopeToken?: string;
  sessionID?: string;
  user: User;
}

export interface AuthPayloadDTO {
  accessToken: string | null;
  accessTokenExpiresAt: string | null;
  client?: Client;
  refreshToken?: string | null;
  organization: Organization;
  firstLogin?: false;
  refreshTokenExpiresAt: string | null;
  scopeToken: string;
  sessionID?: string;
  pushNotificationKey?: string;
  user: {
    isAdmin: boolean;
    email?: string;
    firstname: string;
    id: string;
    lastname: string;
    username?: string;
    eulaStatus?: boolean;
    workStatus?: string;
    loginMethods: string[];
  };
}

export interface Client {
  accessTokenLifetime: number;
  client_id: string;
  client_secret: string;
  grants: string[];
  id: number;
  name: string | null;
  redirect_uri: string;
  refreshTokenLifetime: number;
  user_id: string;
}

export interface HandleContinueLoginAddressParams {
  address?: string;
  auth0Id?: string;
  workOS?: boolean;
}

export enum AuthSSOVendors {
  WORKOS = 'workos',
  AUTH0 = 'auth0',
}
