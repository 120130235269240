import {ApolloClient} from 'apollo-client';
import * as R from 'ramda';
import {AuthPayload, Message, ReadReceiptFromWs, ChatWsPayload, MessageWsPayload, Chat} from 'src/types';
import {REGULAR} from 'src/constants/privileges';
import GetChatsQuery from 'src/gql/query/GetChatsQuery';
import transformWsChatToGraphQL from 'src/utils/messengerHelper/transformWsChatToGraphQL';
import getChatFromId, {tryReadChatQuery} from 'src/utils/messengerHelper/getChatFromId';
import transformWsMessageToGraphQL from 'src/utils/messengerHelper/transformWsMessageToGraphQL';
import GetChatQuery from 'src/gql/query/GetChatQuery';
import getMessages, {messagesLens} from 'src/utils/messengerHelper/getMessagesFromChat';
import transformWsReadReceiptToGraphQL from 'src/utils/messengerHelper/transformWsReadReceiptToGraphQL';
import getMessageFragment from 'src/utils/messengerHelper/getMessageFragment';
import MessageFragment from 'src/gql/fragment/MessageFragment';
import MessagesDeliveredMutation from 'src/gql/mutation/MessagesDeliveredMutation';
import GetColleaguesQuery from 'src/gql/query/GetColleaguesQuery';
import tryReadChatsQuery, {tryReadColleaguesQuery} from 'src/utils/messengerHelper/tryReadCachedQuery';
import {
  BatchedDeliveryMutations,
  MemberLeftPayload,
  MemberAddedPayload,
  EscalationWsPayload,
} from 'src/types/SocketHandler';
import store from 'src/redux';
import removeOptimisticMessage from 'src/utils/messengerHelper/RemoveOptimisticMessage';
import {MESSENGER} from 'src/constants/routerPathName';
import {TEMPLATE} from 'src/constants/messageTypes';
import sleep from 'src/utils/sleep';
import LocalNotificationManager from 'src/notifications/LocalNotificationManager';
import FetchAllActiveEscalations from 'src/gql/query/FetchAllActiveEscalations';
import {StatMessagePriority, UrgentMessagePriority} from '../constants/messageTypes';
import client from 'src/apollo';
import GetMessageQuery from 'src/gql/query/GetMessageQuery';
import {MESSAGES_DELIVERED} from 'src/gql/v2/mutation/MessagesDeliveredMutation';

const IN_PROD = process.env.NODE_ENV === 'production';
const DELIVERY_RECEIPTS_SEND_INTERVAL = 10000;
const READ_RECEIPTS_SET_INTERVAL = 3000;

export default class ApolloSocketIOHandlerWithFirebase {
  public client: ApolloClient<any>;
  public authInfo: AuthPayload;
  public deliveryReceipts: BatchedDeliveryMutations = {};
  public readReceipts: ReadReceiptFromWs[] = [];

  private updateUnreadPriorityMessages = R.curry((isCurrentUser: boolean, chatQuery: Chat, message: Message) =>
    R.evolve(
      {
        chat: {
          unreadPriorityMessages: (unreadPriorityMessages) =>
            [StatMessagePriority, UrgentMessagePriority].includes(message.priorityType) && !isCurrentUser
              ? [...unreadPriorityMessages, Number(message.id)]
              : unreadPriorityMessages,
        },
      },
      chatQuery,
    ),
  );

  private updateMessages = R.curry((newMessages: Message[], chatQuery: Chat) =>
    R.set(R.compose(R.lensProp('chat'), messagesLens), newMessages, chatQuery),
  );

  constructor(apolloClient, authInfo) {
    this.client = apolloClient;
    this.authInfo = authInfo;
    this.deliveryReceipts = {};

    setInterval(this.sendDeliveryReceipts, DELIVERY_RECEIPTS_SEND_INTERVAL);
    setInterval(this.writeReadReceipts, READ_RECEIPTS_SET_INTERVAL);
  }

  public async handleMemberLeft(payload: MemberLeftPayload) {
    const {
      user: {id: currentUserId},
    } = this.authInfo;
    const {chatId, memberId} = payload;
    if (memberId === currentUserId) {
      const localChatsQuery = await tryReadChatsQuery();
      const chatsQuery = localChatsQuery ? localChatsQuery : (await this.client.query({query: GetChatsQuery})).data;

      const chats = R.pathOr([], ['chats', 'chats'], chatsQuery) as Chat[];
      const filteredChats = chats.filter((chat) => chat.id !== chatId);
      chatsQuery.chats.chats = filteredChats;
      this.client.writeQuery({
        query: GetChatsQuery,
        data: chatsQuery,
      });
      window.location.assign(`/${MESSENGER}/home`);
    } else {
      const chatQuery = await getChatFromId(chatId, this.client);
      if (chatQuery) {
        const newChatQuery = R.evolve({
          chat: {
            members: R.filter(R.complement(R.propEq('id', memberId))),
          },
        })(chatQuery);
        this.client.writeQuery({
          query: GetChatQuery,
          variables: {chatId},
          data: newChatQuery,
        });
      }
    }
  }

  public async handleMemberAdded(payload: MemberAddedPayload) {
    try {
      const {chatId, memberIds} = payload;
      const {
        user: {id: currentUserId},
      } = this.authInfo;
      // no need to handle new chat
      if (memberIds.includes(currentUserId)) return;
      // else get users from GetColleaguesQuery and write to cache base on memberIds

      const localColleaguesQuery = await tryReadColleaguesQuery();
      const colleaguesQuery = localColleaguesQuery
        ? localColleaguesQuery
        : (await this.client.query({query: GetColleaguesQuery})).data;

      const chatQuery = await getChatFromId(chatId, this.client);

      if (chatQuery && colleaguesQuery) {
        const colleagues = R.pathOr([], ['colleagues'], colleaguesQuery);
        const selectedColleagues = colleagues.filter((c) => memberIds.includes(c.id));

        const chatAddedColleagues = selectedColleagues.map((user) => ({
          ...user,
          privilege: REGULAR,
          ___typename: 'ChatMember',
        }));
        const newChatQuery = R.evolve({
          chat: {
            members: R.concat(chatAddedColleagues),
          },
        })(chatQuery);
        this.client.writeQuery({
          query: GetChatQuery,
          variables: {chatId},
          data: newChatQuery,
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  public async handleIncomingMessage(messagePayload: MessageWsPayload) {
    try {
      const {
        user: {id: currentUserId},
      } = this.authInfo;
      const {organizationId: messageOrganizationId} = messagePayload;
      const message = await transformWsMessageToGraphQL(messagePayload, this.client);
      const {chatId = ''} = message;

      const isCurrentUser = message.sender && message.sender.id === currentUserId;

      // client page will be refreshed when click on notification from other org
      const currentOrganization = store.getState().organization;
      /* message from anther */

      if (messageOrganizationId !== currentOrganization.organizationId) {
        if (!isCurrentUser) {
          //LocalNotificationManager.displayMessageNotification(message, null, true);
          if (message.chatId) {
            this.addToDeliveryReceipts(message.id, message.chatId, String(messageOrganizationId));
          }
        }
        return;
      }
      // else ensure chat is in cache, then update receipts and chat messages
      const chatQuery = await this.getFreshChatQuery(chatId);

      if (message.chatId) this.addToDeliveryReceipts(message.id, message.chatId);
      if (isCurrentUser) {
        removeOptimisticMessage(message, chatId);
      } else {
        //LocalNotificationManager.displayMessageNotification(message, chatQuery.chat, true);
      }

      await this.updateChat(chatId, message, isCurrentUser);
    } catch (e) {
      console.error(e);
    }
  }

  public async handleNewChat(chat: ChatWsPayload) {
    try {
      const newChat = transformWsChatToGraphQL(chat, this.client);

      const localChatsQuery = await tryReadChatsQuery();
      const chatsQuery = localChatsQuery ? localChatsQuery : (await this.client.query({query: GetChatsQuery})).data;

      const chats = R.pathOr([], ['chats', 'chats'], chatsQuery) as Chat[];
      // if its existing chat, it will be handle @handleIncomingMessage
      if (chats.find((storedChat) => storedChat.id === newChat.id)) return;
      // else write the new chat into the local cache
      const {data} = await this.client.query({
        query: GetChatQuery,
        variables: {chatId: chat.id},
      });
      if (!chats.find((storedChat) => storedChat.id === newChat.id)) {
        chats.unshift(data.chat);
        this.client.writeQuery({
          query: GetChatsQuery,
          data: chatsQuery,
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  public addToReadReceipts(wsReadReceipt: ReadReceiptFromWs) {
    this.readReceipts.push(wsReadReceipt);
  }

  public writeReadReceipts = async () => {
    const batchedReadreceiptsClone = [...this.readReceipts];
    this.readReceipts = [];
    for (const receipt of batchedReadreceiptsClone) {
      await this.handleMessageRead(receipt);
    }
  };

  public handleMessageRead = async (wsReadReceipt: ReadReceiptFromWs) => {
    return new Promise<void>(async (resolve) => {
      const readReceipt = transformWsReadReceiptToGraphQL(wsReadReceipt, this.client);

      if (!readReceipt || !readReceipt.user || !readReceipt.user.id) {
        // i.e. might not exist if user is from another organization
        return resolve();
      }

      const userId = readReceipt.user.id;
      try {
        const {messageId} = readReceipt;
        const messageFragment = getMessageFragment(messageId, this.client);

        if (
          messageFragment && // prevent overlapping with update readAllReceipts from the client
          !messageFragment.readBy.find((receipts) => receipts.user.id === userId)
        ) {
          messageFragment.readBy.push(readReceipt);

          // console.log(`Readby for ${messageId}`, messageFragment.readBy)
          this.client.writeFragment({
            id: `Message:${messageId}`,
            fragment: MessageFragment,
            fragmentName: 'MessageFragment',
            data: {
              ...messageFragment,
            },
          });

          // TODO: needs to investigate out if apollo has write locks for writefragment
          // this is causing readreceipts to be overwriten by outdated data
          await sleep(500);
        }
        resolve();
      } catch (e) {
        // try-catch surround https://github.com/apollographql/apollo-client/issues/1542
        if (!IN_PROD) console.error(e);
        resolve();
      }
    });
  };

  public async handleConnectionStatus(connectionStatus: string) {
    try {
      this.client.writeData({
        data: {
          connectionStatus,
        },
      });
    } catch (e) {
      console.error(e);
    }
  }

  private async fetchTemplateMessageToUpdate(chatId, messageId) {
    const {data} = await client.query({
      query: GetMessageQuery,
      variables: {
        chatId,
        messageId,
      },
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    });

    return data;
  }

  private async updateChat(chatId: string, message: Message, isCurrentUser: boolean) {
    try {
      const chatQuery = this.client.cache.readQuery({
        query: GetChatQuery,
        variables: {chatId},
      }) as {chat: Chat};
      if (chatQuery) {
        const messages = getMessages(chatQuery.chat);
        const messageAlreadyExist = messages.find((pastMessage) => String(pastMessage.id) === String(message.id));
        let newMessages;
        if (message?.type === TEMPLATE) {
          let data = await this.fetchTemplateMessageToUpdate(chatId, message.id);
          let templateMessage = data?.chat?.message;
          newMessages = messageAlreadyExist ? [...messages] : [templateMessage, ...messages];
          message.template = templateMessage.template;
        } else if (message?.repliedTo && message?.repliedTo?.type === TEMPLATE) {
          let data = await this.fetchTemplateMessageToUpdate(chatId, message.repliedTo.id);
          let messagesCopy = messages;
          let templateMessageIndex = messagesCopy.findIndex((_message) => _message.id === message.repliedTo?.id);
          const template = messagesCopy[templateMessageIndex].template;
          if (template) {
            template.status = data?.chat?.message?.template?.status;
          }
          newMessages = messageAlreadyExist ? [...messages] : [message, ...messagesCopy];
        } else {
          newMessages = messageAlreadyExist ? [...messages] : [message, ...messages];
        }

        const newQuery = R.pipe(this.updateUnreadPriorityMessages(isCurrentUser), this.updateMessages(newMessages))(
          chatQuery,
          message,
        );

        // for unread message indication, backend does not support this yet
        // is will be the first message id from a socket event util it gets cleared
        if (!newQuery.chat.lastUnreadMessage && !isCurrentUser) {
          newQuery.chat.lastUnreadMessage = {
            id: message.id,
            __typename: 'Message',
          };
        }

        // update last message then writes to cache
        if (message.message.includes('renamed')) {
          newQuery.chat.title = message.message.split('"')[1];
        }

        newQuery.chat.lastMessage = {...message};
        this.client.writeQuery({
          query: GetChatQuery,
          variables: {chatId},
          data: newQuery,
        });
      }
    } catch (e) {
      // try-catch surround https://github.com/apollographql/apollo-client/issues/1542
      if (!IN_PROD) console.error(e);
    }
  }

  private async getFreshChatQuery(chatId: string) {
    try {
      const localChatsQuery = await tryReadChatsQuery();
      const chatsQuery = localChatsQuery ? localChatsQuery : (await this.client.query({query: GetChatsQuery})).data;
      const chats = R.pathOr([], ['chats', 'chats'], chatsQuery) as Chat[];

      let chatQuery;
      // check if the query exist in the local cache, else fetch and write into chats
      const msgedChat = chats.find((storedChat) => storedChat.id === chatId);
      if (msgedChat) {
        chatQuery = await getChatFromId(chatId, this.client);
      } else {
        const {data} = await this.client.query({
          query: GetChatQuery,
          variables: {chatId},
        });
        chats.unshift(data.chat);
        this.client.writeQuery({
          query: GetChatsQuery,
          data: chatsQuery,
        });
        chatQuery = data;
      }
      return chatQuery;
    } catch (e) {
      if (!IN_PROD) console.error(e);
    }
  }

  private sendDeliveryReceipts = async () => {
    const chatIds = Object.keys(this.deliveryReceipts);

    for (const chatId of chatIds) {
      if (
        this.deliveryReceipts[chatId] &&
        this.deliveryReceipts[chatId].messageIds &&
        this.deliveryReceipts[chatId].messageIds.length > 0
      ) {
        let scopeToken: string | null = null;

        if (this.deliveryReceipts[chatId].organizationId) {
          const {organizationId} = this.deliveryReceipts[chatId];
          const newScope = {organizationId};
          scopeToken = btoa(JSON.stringify(newScope));
        }
        try {
          // no need to await this
          this.client.mutate({
            mutation: MESSAGES_DELIVERED,
            fetchPolicy: 'no-cache',
            errorPolicy: 'ignore',
            context: scopeToken
              ? {
                  headers: {
                    'hypercare-scope': scopeToken,
                  },
                }
              : undefined,
            variables: {
              messageIds: this.deliveryReceipts[chatId].messageIds,
              chatId,
            },
          });
          delete this.deliveryReceipts[chatId];
        } catch {
          delete this.deliveryReceipts[chatId];
        }
      }
    }
  };

  private addToDeliveryReceipts(messageId: string, chatId: string, organizationId?: string) {
    this.deliveryReceipts[chatId] = this.deliveryReceipts[chatId] || {
      messageIds: [],
      organizationId: null,
    }; // init

    this.deliveryReceipts[chatId].messageIds = this.deliveryReceipts[chatId].messageIds || [];
    this.deliveryReceipts[chatId].messageIds.push(messageId);

    if (organizationId) this.deliveryReceipts[chatId].organizationId = organizationId;
  }

  public async handleRefetchActiveEscalation(payload: EscalationWsPayload) {
    console.info('escalation socket received', payload);
    try {
      // TODO: proper local cache management from payload than always fetch from network
      await this.client.query({query: FetchAllActiveEscalations, fetchPolicy: 'network-only'});
      const chatId = payload.activeEscalation.chat.id;
      const potentialChatQuery = tryReadChatQuery(this.client, chatId);
      if (potentialChatQuery) {
        this.client.query({
          query: GetChatQuery,
          variables: {chatId},
          fetchPolicy: 'network-only',
        });
      }
    } catch (e) {
      console.error(e);
    }
  }
}
