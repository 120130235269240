import * as React from 'react';
import styled from '@emotion/styled';
import {HeaderDiv} from 'src/styles/styled-components/StyledMessagesHeader';
import {useDispatch} from 'react-redux';
import {actions} from 'src/redux/actions/colleagues';
import CreateNewChatInputDropdownV2 from './CreateNewChatInputDropDownV2';
import {typedUseSelector} from 'src/redux';
import {SiteScope, UserSelectOptionType} from 'src/types';
import {SitesFilter} from 'src/pages/ContactsPage/filters/SitesFilter';
import {IsFeatureFlagEnabled} from 'src/utils/FeatureFlagManager';
import {FeatureFlagResult} from 'src/utils/FeatureFlags';
import HypercareSchedulingViewModel from '../../../LocatingPage/viewModels/HypercareSchedulingViewModel';
import {MinimalSiteData} from '../../../../gql/query/FetchSitesForOrganization';
import CreateNewChatInputDropdownSTA from './CreateNewChatInputDropdownSTA';

const SendMessageTo = styled.span`
  color: ${(props) => props.theme.colors.warmGrey};
  font-size: 0.8em;
  white-space: nowrap;
  padding-right: 0.5em;
`;

const CreateChatHeaderDiv = styled(HeaderDiv)`
  border-radius: 2px;
  border: 1px solid var(--Border-Grey, #d8d8d8);
  background: var(--Translucent-White, #f6f6f9);
  position: relative;
  display: flex;
  flex-wrap: wrap;
  height: 40px;
  padding-left: 16px;
  margin: 12px 16px 12px 16px;
  justify-content: flex-start;
`;

const CreateNewChatHeaderV2 = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(actions.clearAllColleagues());
  }, []);

  const [selectedSitesIds, setSelectedSitesIds] = React.useState<number[]>([]);
  const [siteFullScope, setSiteFullScope] = React.useState<SiteScope[]>([]);
  const selectedColleagues: Set<{id: string; label: string; role?: string}> = typedUseSelector(
    (state) => state.colleagues.selectedColleagues,
  );
  const pcSiteFiltersFlagEnabled = IsFeatureFlagEnabled(FeatureFlagResult.pcSiteFilters);
  const STAFlag = IsFeatureFlagEnabled(FeatureFlagResult.singleTenantAccountFlag);

  const organizationId = typedUseSelector((state) => state.organization.organizationId);
  const setSelectedColleagues = (selectedColleage) => {
    dispatch(actions.setSelectedColleagues(selectedColleage));
  };

  const reduxStoreSelectedColleagues = Array.from(selectedColleagues).map((colleague) => {
    return {
      value: colleague.id,
      label: colleague.label,
      role: colleague.role,
    } as UserSelectOptionType;
  });

  const {getSitesForOrganization} = HypercareSchedulingViewModel();

  React.useEffect(() => {
    const getSitesForOrg = async () => {
      const result = await getSitesForOrganization(organizationId || 0, false);

      if (result?.siteFullScope) {
        setSiteFullScope(result.siteFullScope.map((s) => ({...s, isAdmin: false, image: '', departments: []})));
      } else {
        setSiteFullScope([]);
      }
    };

    getSitesForOrg();
  }, []);

  const siteOptions = React.useMemo(() => {
    return siteFullScope.map((site) => ({
      id: site.id,
      label: site.name,
      value: site,
    }));
  }, [siteFullScope]);

  const sitesScope = React.useMemo(() => {
    return selectedSitesIds.map((siteId) => ({
      id: Number(siteId),
      type: 'site',
    }));
  }, [selectedSitesIds]);

  return (
    <div style={{borderBottom: '1px solid #D8D8D8'}}>
      <div style={{margin: '12px 16px 12px 16px'}}>
        {pcSiteFiltersFlagEnabled && (
          <SitesFilter
            selectedSitesIds={selectedSitesIds}
            setSelectedSitesIds={setSelectedSitesIds}
            siteOptions={siteOptions}
          />
        )}
      </div>
      <CreateChatHeaderDiv>
        <SendMessageTo>To:</SendMessageTo>
        {!STAFlag ? (
          <CreateNewChatInputDropdownSTA
            reduxStoreSelectedColleagues={reduxStoreSelectedColleagues}
            setReduxSelectedColleagues={setSelectedColleagues}
            scopes={sitesScope}
          />
        ) : (
          <CreateNewChatInputDropdownV2
            reduxStoreSelectedColleagues={reduxStoreSelectedColleagues}
            setReduxSelectedColleagues={setSelectedColleagues}
            scopes={sitesScope}
          />
        )}
      </CreateChatHeaderDiv>
    </div>
  );
};

export default CreateNewChatHeaderV2;
