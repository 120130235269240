import ApiHelper from 'src/api';

class CreateNewOrgViewModel {
  nextButtonPressed;

  constructor(nextButtonPressed) {
    this.nextButtonPressed = nextButtonPressed;
  }

  handleNextButtonPressed = async (values) => {
    const payload = {organizationDetails: {name: values.orgName}};
    try {
      const checkDuplicateOrg = await ApiHelper.PublicEndpoints.getOrganizationByName(values.orgName);
      if (checkDuplicateOrg.data?.findOrganizationByName.__typename === 'OrganizationNotFound') {
        this.nextButtonPressed(values.orgName);
      } else {
        return {error: 'An organization with this name already exists'};
      }
    } catch (error: any) {
      let errorMsg = 'Unknown error occurred, please check your internet connection and try again';
      if (error.errors) {
        errorMsg = error.errors[0].message;
      }
      return errorMsg;
    }
  };
}

export default CreateNewOrgViewModel;
