import {useQuery} from 'react-apollo';
import {getOrganizationalUnitObject} from 'src/utils/organizationHelper/getOrganizationalUnitObject';
import {OrganizationalUnitInputType} from '../../gql/v2/types/input';
import {
  FETCH_ORGANIZATION_USERS_COUNT,
  FETCH_PAGINATED_USERS_QUERY,
  FetchPaginatedUsersQueryResponse,
  FetchTotalUserCountResponse,
} from 'src/gql/v2/query/FetchPaginatedUsersQuery';
import {SEARCH_USERS_QUERY, SearchUsersQueryResponse} from 'src/gql/v2/query/SearchPaginatedUsersQuery';
import {ApolloQueryResult} from 'apollo-client';
import client from 'src/apollo';
import {
  FETCH_PAGINATED_INVITES_QUERY,
  FetchPaginatedInvitesQueryResponse,
} from 'src/gql/v2/query/FetchPaginatedInvites';
import FetchDepartmentUsers from '../../gql/query/FetchDepartmentUsers';
import {FetchDepartmentUsersResult, UserInviteStatusStatus} from '../../types';
import {FETCH_USER_ADDRESSES_QUERY, FetchUserAddressQueryResult} from '../../gql/v2/query/FetchUserProfileQuery';
import FetchUnreadChatCount, {FetchUnreadChatCountResponse} from '../../gql/query/FetchUnreadChatCount';
import {
  FETCH_PAGINATED_INVITES,
  FetchPaginatedInvitesSTAQueryResponse,
} from 'src/gql/v2/query/FetchPaginatedSentInvites';
import {
  SEARCH_PAGINATED_USERS_STA,
  SearchUsersSTAQueryResponse,
} from '../../gql/v2/query/SearchPaginatedUsersInScopeQuery';
import {
  FETCH_USERS_IN_SCOPE_CURSOR_QUERY,
  FetchUsersSTAQueryResponse,
} from 'src/gql/v2/query/FetchUsersInScopeCursorQuery';

export type SiteScopes = {
  id: number;
  type: string;
};

export type PageInfo = {
  cursor: string | null;
  direction?: string;
  pageSize: number;
};

export type FetchPaginatedRequestInput = {
  direction: string;
  continuationId: string | null;
  scopes?: SiteScopes[];
};

export type InviteFilters = {
  searchQuery?: string;
  inviteStatus?: UserInviteStatusStatus;
  organizationalUnits?: SiteScopes[];
  memberStatus?: string[];
};

export type FetchPaginatedRequestInputSTA = {
  text?: string;
  scopes?: SiteScopes[];
  pageInfo: PageInfo;
  filters?: InviteFilters;
};

export type SearchRequestInput = {
  text: string;
  continuationId: string | null;
  limit: number;
  options?: {
    status: string[];
  };
  scopes?: SiteScopes[];
};

export const UserRepository = () => {
  const useFetchPaginatedUsers = ({continuationId, direction, scopes}: FetchPaginatedRequestInput) => {
    const result = useQuery<FetchPaginatedUsersQueryResponse, FetchPaginatedRequestInput & OrganizationalUnitInputType>(
      FETCH_PAGINATED_USERS_QUERY,
      {
        variables: {
          organizationalUnit: getOrganizationalUnitObject(),
          direction,
          continuationId,
          scopes,
        },
        fetchPolicy: 'no-cache',
      },
    );
    return {
      ...result,
    };
  };

  const useFetchOrganizationUserCount = () => {
    const result = useQuery<FetchTotalUserCountResponse, FetchPaginatedRequestInput & OrganizationalUnitInputType>(
      FETCH_ORGANIZATION_USERS_COUNT,
      {
        variables: {
          organizationalUnit: getOrganizationalUnitObject(),
          direction: 'next',
          continuationId: 'null',
        },
      },
    );
    return {
      ...result,
    };
  };

  const useFetchPaginatedInvites = ({continuationId, direction}: FetchPaginatedRequestInput) => {
    const result = useQuery<
      FetchPaginatedInvitesQueryResponse,
      FetchPaginatedRequestInput & OrganizationalUnitInputType
    >(FETCH_PAGINATED_INVITES_QUERY, {
      variables: {
        organizationalUnit: getOrganizationalUnitObject(),
        direction,
        continuationId,
      },
      fetchPolicy: 'no-cache',
    });
    return {
      ...result,
    };
  };

  const useFetchPaginatedUsersInScopeSTA = ({pageInfo, filters, scopes}) => {
    const result = useQuery<FetchUsersSTAQueryResponse>(FETCH_USERS_IN_SCOPE_CURSOR_QUERY, {
      variables: {
        organizationalUnit: getOrganizationalUnitObject(),
        pageInfo,
        text: '',
        filters: filters
          ? filters
          : {
              organizationalUnits: scopes,
            },
      },
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    });
    return {
      ...result,
    };
  };

  const useFetchPaginatedInvitesSTA = ({pageInfo, filters}: FetchPaginatedRequestInputSTA) => {
    const result = useQuery<FetchPaginatedInvitesSTAQueryResponse>(FETCH_PAGINATED_INVITES, {
      variables: {
        pageInfo: pageInfo!,
        filters: filters
          ? filters
          : {
              inviteStatus: ['pending_admin_approval'],
            },
      },
      fetchPolicy: 'no-cache',
    });
    return {
      ...result,
    };
  };

  const searchUserInvitesSTA = async ({pageInfo, filters}: FetchPaginatedRequestInputSTA) => {
    try {
      const searchUsersDataQuery: ApolloQueryResult<FetchPaginatedInvitesSTAQueryResponse> = await client.query({
        query: FETCH_PAGINATED_INVITES,
        variables: {
          pageInfo: pageInfo!,
          filters: filters ? filters : {},
        },
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      });
      return searchUsersDataQuery;
    } catch (err) {
      console.error(err, 'Error occurred when searching for users');
    }
  };

  const searchUserInScopeSTA = async ({text, pageInfo, filters, scopes}: FetchPaginatedRequestInputSTA) => {
    try {
      const searchUsersDataQuery: ApolloQueryResult<SearchUsersSTAQueryResponse> = await client.query({
        query: SEARCH_PAGINATED_USERS_STA,
        variables: {
          organizationalUnit: getOrganizationalUnitObject(),
          pageInfo,
          filters: filters
            ? filters
            : {
                searchQuery: text,
                organizationalUnits: scopes,
              },
        },
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      });
      return searchUsersDataQuery;
    } catch (err) {
      console.error(err, 'Error occurred when searching for users');
    }
  };

  const searchUsers = async ({text, limit, continuationId, options, scopes}: SearchRequestInput) => {
    try {
      const searchUsersDataQuery: ApolloQueryResult<SearchUsersQueryResponse> = await client.query({
        query: SEARCH_USERS_QUERY,
        variables: {
          text,
          continuationId,
          limit,
          options,
          scopes,
        },
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      });
      return searchUsersDataQuery;
    } catch (err) {
      console.error(err, 'Error occurred when searching for users');
    }
  };

  const useFetchDepartmentUsers = ({departmentId}: {departmentId: number}) => {
    const result = useQuery<FetchDepartmentUsersResult>(FetchDepartmentUsers, {
      variables: {
        departmentId: departmentId,
      },
      fetchPolicy: 'no-cache',
    });

    return {
      ...result,
    };
  };

  const useFetchUserAddresses = () => {
    const result = useQuery<FetchUserAddressQueryResult>(FETCH_USER_ADDRESSES_QUERY, {
      variables: {
        organizationalUnit: getOrganizationalUnitObject(),
      },
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    });
    return {
      ...result,
    };
  };

  const fetchUnreadChatCountForUser = async (accessToken: string) => {
    try {
      return await client.query<FetchUnreadChatCountResponse>({
        query: FetchUnreadChatCount,
        variables: {
          customAccessToken: accessToken,
        },
        fetchPolicy: 'no-cache',
      });
    } catch (e) {
      return {error: 'Error occurred while fetching unread chat count'};
    }
  };

  return {
    useFetchPaginatedUsers,
    searchUsers,
    useFetchPaginatedInvites,
    useFetchPaginatedInvitesSTA,
    useFetchOrganizationUserCount,
    useFetchDepartmentUsers,
    useFetchUserAddresses,
    fetchUnreadChatCountForUser,
    searchUserInvitesSTA,
    useFetchPaginatedUsersInScopeSTA,
    searchUserInScopeSTA,
  };
};
