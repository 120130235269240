import gql from 'graphql-tag';

export const ACK_NOTIFICATION_MUTATION = gql`
  mutation AckPushNotification($notificationId: Int!) {
    selfMutation {
      notificationReceived(notificationId: $notificationId) {
        ... on DeviceNotRegistered {
          message
        }
        ... on SelfUser {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
