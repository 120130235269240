import React, {useState} from 'react';
import styled from '@emotion/styled';
import {Avatar, CircularProgress, ListItemAvatar, ListItemText} from '@mui/material';
import {getUpperCaseInitials} from 'src/utils/user';
import InfiniteScroll from 'react-infinite-scroller';
import {FetchPaginatedUsersQueryResponse} from 'src/gql/v2/query/FetchPaginatedUsersQuery';
import {getOrganizationalUnitObject} from 'src/utils/organizationHelper/getOrganizationalUnitObject';
import {usePaginatedDataState} from 'src/pages/ContactsPage/ContactListSection/hooks/usePaginatedDataState';
import {PaginatedUsersType} from 'src/types/PaginatedTypes';
import {SiteScopes} from 'src/data/repository/UserRepository';
import {UserViewModel} from '../../viewModels/UserViewModel';
import {PaginatedSearch} from '../hooks/usePaginatedSearchStateSTA';
import {FetchUsersSTAQueryResponse} from 'src/gql/v2/query/FetchUsersInScopeCursorQuery';

const UserListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const UserItem = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1em;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface Props {
  contactList: PaginatedUsersType[];
  fetchMorePaginatedUserData: any;
  paginatedUserData?: FetchUsersSTAQueryResponse;
  localSearchText: string;
  searchUserData: PaginatedSearch | null;
  setSearchUserData: React.Dispatch<React.SetStateAction<PaginatedSearch | null>>;
  isDoneRows: boolean;
  setIsDoneRows: React.Dispatch<React.SetStateAction<boolean>>;
  seenNextCursors: string[];
  setSeenNextCursors: React.Dispatch<React.SetStateAction<string[]>>;
  scopes: SiteScopes[];
}
const ContactListSTA = ({
  contactList,
  fetchMorePaginatedUserData,
  paginatedUserData,
  searchUserData,
  setSearchUserData,
  localSearchText,
  isDoneRows,
  setIsDoneRows,
  seenNextCursors,
  setSeenNextCursors,
  scopes,
}: Props) => {
  const {additionalRows, setAdditionalRows, isLoadingAdditionalData, setIsLoadingAdditionalData} =
    usePaginatedDataState();

  const newInvitedUserListData = [...contactList, ...(additionalRows || [])];

  const [continuationId, setContinuationId] = useState(
    paginatedUserData?.organizationalUnitQuery.organizationalUnit.members.pageInfo.nextCursor,
  );

  const {getSearchPaginatedUsersInScope} = UserViewModel();

  const getMorePaginatedSearchData = async () => {
    const continuationId = searchUserData?.pageInfo.nextCursor;

    if (!continuationId || seenNextCursors.includes(continuationId)) {
      setIsDoneRows(true);
      return;
    }

    try {
      const result = await getSearchPaginatedUsersInScope({
        text: localSearchText,
        scopes,
        pageInfo: {
          cursor: continuationId,
          direction: 'next',
          pageSize: 30,
        },
      });

      if (result.error !== undefined) {
        setIsDoneRows(true);
        return;
      } else {
        setSearchUserData({
          ...searchUserData,
          pageInfo: {
            ...searchUserData?.pageInfo,
            nextCursor: result.pageInfo.nextCursor,
          },
          users: [...(searchUserData?.users ?? []), ...result.users],
        });

        if (result.pageInfo.nextCursor === null) {
          setIsDoneRows(true);
        }
      }
    } finally {
      setSeenNextCursors([...seenNextCursors, continuationId]);
    }
  };

  const getMoreData = async () => {
    setIsLoadingAdditionalData(true);
    if (
      !continuationId ||
      paginatedUserData?.organizationalUnitQuery.organizationalUnit.members.pageInfo.hasNextPage === false
    ) {
      setIsDoneRows(true);
      return;
    }

    try {
      await fetchMorePaginatedUserData({
        variables: {
          scopes,
          pageInfo: {
            cursor: continuationId,
            direction: 'next',
            pageSize: 30,
          },
        },
        updateQuery: (
          previousResult: FetchUsersSTAQueryResponse,
          {fetchMoreResult}: {fetchMoreResult: FetchUsersSTAQueryResponse},
        ) => {
          const newFetchedUsers = fetchMoreResult.organizationalUnitQuery.organizationalUnit.members;

          if (newFetchedUsers.pageInfo.nextCursor === null) {
            setIsDoneRows(true);
          }
          setAdditionalRows([...additionalRows, ...(newFetchedUsers.users ?? [])]);
          setContinuationId(newFetchedUsers.pageInfo.nextCursor);
        },
      });
    } finally {
      setSeenNextCursors([...seenNextCursors, continuationId]);
    }
  };

  return (
    <UserListWrapper>
      <div style={{maxHeight: `calc(100vh - 205px)`, overflow: 'auto'}} id="scrollableDiv">
        <InfiniteScroll
          hasMore={!isDoneRows}
          loadMore={localSearchText.length > 1 ? getMorePaginatedSearchData : getMoreData}
          initialLoad={false}
          useWindow={false}
        >
          {newInvitedUserListData.map((contact) => (
            <UserItem key={contact.id} onClick={() => window.routerHistory.push(`/contacts/${contact.id}`)}>
              <ListItemAvatar>
                {contact?.profilePic ? (
                  <Avatar src={contact?.profilePic.url} />
                ) : (
                  <Avatar>{getUpperCaseInitials(contact)}</Avatar>
                )}
              </ListItemAvatar>
              <ListItemText
                primary={`${contact?.firstname || ''} ${contact?.lastname || ''}`}
                secondary={contact?.role}
              />
            </UserItem>
          ))}
          {isLoadingAdditionalData && !isDoneRows && (
            <LoadingContainer>
              <CircularProgress />
            </LoadingContainer>
          )}
        </InfiniteScroll>
      </div>
    </UserListWrapper>
  );
};

export default ContactListSTA;
