import {useState} from 'react';
import {PaginatedUsersType} from 'src/types/PaginatedTypes';
export const usePaginatedDataStateSTA = () => {
  const [isLoadingAdditionalData, setIsLoadingAdditionalData] = useState(false);
  const [isDoneRows, setIsDoneRows] = useState(false);
  const [additionalRows, setAdditionalRows] = useState<PaginatedUsersType[]>([]);
  const [seenNextCursors, setSeenNextCursors] = useState<string[]>([]);

  return {
    isLoadingAdditionalData,
    setIsLoadingAdditionalData,
    isDoneRows,
    setIsDoneRows,
    additionalRows,
    setAdditionalRows,
    seenNextCursors,
    setSeenNextCursors,
  };
};
