import * as Yup from 'yup';
import {emailValidationSchema} from 'src/utils/validations/signupFormValidationSchema';

const PHONE_REGEX =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

Yup.addMethod(Yup.array, 'unique', function (message, mapper = (a) => a) {
  // @ts-ignore
  return this.test('unique', message, function (list) {
    return list.length === new Set(list.map(mapper)).size;
  });
});

export const phoneNumberValidationSchema = Yup.string()
  .trim()
  .required('Phone number is required!')
  .matches(PHONE_REGEX, 'Phone number is invalid')
  .min(6, 'Please enter a valid phone number')
  .max(15, 'Please enter a valid phone number');

export const phoneNumberOptionalValidationSchema = Yup.string()
  .trim()
  .notRequired()
  .transform((value) => {
    return value?.replace(/^\d{1,3}[\s\-]?(?!\d)/, '').trim() || '';
  })
  .test('phone-number', 'Phone number is invalid', (value) => {
    return !value || value.match(PHONE_REGEX);
  })
  .max(15, 'Please enter a valid phone number');

export const profileFormContactInfoValidationSchema = Yup.object().shape({
  address: Yup.string()
    .nullable()
    .when('type', {
      is: 'phoneNumber',
      then: phoneNumberValidationSchema,
    })
    .when('type', {
      is: 'email',
      then: emailValidationSchema,
    })
    .when('type', {
      is: 'pager',
      then: Yup.string().required('Pager Number is required!'),
    }),
  ordinal: Yup.string().required('Required'),
  access: Yup.string().required('Required'),
  type: Yup.string().required('Required'),
});

export const profileFormValidationSchema = Yup.object().shape({
  firstname: Yup.string().required('First name is required!'),
  lastname: Yup.string().required('Last name is required!'),
  role: Yup.string().max(128, 'Invalid').nullable(),
});

export const profileFormAddressValidationSchema = Yup.object().shape({
  addresses: Yup.array()
    .of(profileFormContactInfoValidationSchema)
    .nullable()
    // @ts-ignore
    .unique('Duplicate addresses occurred!', (a) => a.address),
});
