import {User} from '@auth0/auth0-react/dist/auth-state';

export interface InvitedUserQueryResult {
  me: {
    sentInvites: InvitedUser[];
  };
}

export type GetOrganizationInviteResult = {
  me: {
    id: string;
    invites: OrganizationInvitation[];
  };
};

export enum InviteStatus {
  'pending_admin_approval' = 'pending_admin_approval',
  'pending_user_acceptance' = 'pending_user_acceptance',
}

export interface InvitedUser {
  id: string;
  user?: User;
  address?: InvitedUserAddress;
  createdAt: string;
  updatedAt: string;
  inviteStatus: string;
  history?: InviteHistory[];
  organization?: InvitesOrganization;
  invitedBy?: User;
  status: string;
}

interface InviteHistory {
  action: string;
  createdAt: string;
}

export interface InvitedUserAddress {
  id?: string;
  verified?: boolean;
  organizationId?: number;
  address: string;
  access?: string;
  ordinal?: string;
  type?: 'email' | 'phoneNumber';
}

export interface InvitesOrganization {
  id: number | string;
  image: string;
  name: string;
  admins: User[];
}
export interface OrganizationInvitation {
  id: number | string;
  invitedBy: User;
  organization: InvitesOrganization;
  inviteStatus: InviteStatus;
}

export type UserInviteStatusStatus = 'pending_admin_approval' | 'pending_user_acceptance';
