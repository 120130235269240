import ApiHelper from 'src/api';

class JoinOrgViewModel {
  nextButtonPressed;
  constructor(nextButtonPressed) {
    this.nextButtonPressed = nextButtonPressed;
  }

  handleNextButtonPressed = async (values) => {
    try {
      const fetchOrgByInviteCode = await ApiHelper.PublicEndpoints.fetchOrganizationByInviteCodeV2(values.inviteCode);
      const orgId = fetchOrgByInviteCode?.data?.findOrganizationByInviteCode.id;
      const organizationUrl = fetchOrgByInviteCode?.data?.findOrganizationByInviteCode.url;
      const orgName = fetchOrgByInviteCode?.data?.findOrganizationByInviteCode.name;
      if (fetchOrgByInviteCode.success) {
        const doesNotContainPassword =
          !fetchOrgByInviteCode?.data?.findOrganizationByInviteCode.loginMethods.includes('password');
        console.log(doesNotContainPassword);

        const loginMethodContainsOtpOrPassword =
          fetchOrgByInviteCode?.data.findOrganizationByInviteCode.loginMethods.includes('otp') ||
          fetchOrgByInviteCode?.data.findOrganizationByInviteCode.loginMethods.includes('password');
        const fetchBasicUser = await ApiHelper.PublicEndpoints.fetchBasicUser(
          values.email,
          fetchOrgByInviteCode.data.findOrganizationByInviteCode.url,
        );
        if (!loginMethodContainsOtpOrPassword) {
          return {
            error: {
              title: 'Please use your work email to join this organization',
              subTitle: 'Contact your admin if you need help.',
            },
          };
        } else if (fetchBasicUser.data?.user.__typename === 'UserNotFound') {
          if (doesNotContainPassword) {
            this.nextButtonPressed(values.inviteCode, orgName, true, orgId, organizationUrl);
          } else {
            this.nextButtonPressed(values.inviteCode, orgName, false, orgId, organizationUrl);
          }
        } else if (fetchBasicUser?.data?.user.__typename === 'MemberBlocked') {
          return {
            error: {
              title: 'Your email is linked to a removed account in the same organization.',
              subTitle: 'Contact your admin to send you a reinvite.',
            },
          };
        } else if (
          fetchBasicUser?.data?.user.memberStatus === 'active' ||
          fetchBasicUser?.data?.user.memberStatus === 'inactive'
        ) {
          return {
            error: {
              title: 'Your email is linked to an existing account in the same organization.',
              subTitle: 'Login to that account or use a different email.',
            },
          };
        }
      } else {
        return {error: 'Invalid invite code'};
      }
    } catch (error: any) {
      let errorMsg = 'Unknown error occurred, please check your internet connection and try again';
      if (error.errors) {
        errorMsg = error.errors[0].message;
      }
      return errorMsg;
    }
  };
}

export default JoinOrgViewModel;
