import gql from 'graphql-tag';
import {OrganizationLoginMethods} from '../../../types';

export type FetchOrganizationByNameResponse = {
  data: {
    searchOrganizationsByName: {
      __typename: string;
      limit: number;
      offset: number;
      pageSize: number;
      total: number;
      organizations: {
        name: string;
        imageURL: string;
        id: number;
        url: string;
        loginMethods: OrganizationLoginMethods[];
        termsOfServiceURL: string;
        privacyPolicyURL: string;
        __typename: string;
      }[];
    };
  };
};

export const SEARCH_ORGANIZATIONS_BY_NAME = gql`
  query SearchOrganizationsByName($name: String!, $pageInfo: CursorPageInput) {
    searchOrganizationsByName(name: $name, pageInfo: $pageInfo) {
      ... on PaginatedOrganizationsResponse {
        __typename
        pageInfo {
          hasNextPage
          startCursor
          endCursor
          totalResults
          resultsCount
          pageSize
          nextCursor
        }
        organizations {
          __typename
          name
          imageURL
          id
          url
          loginMethods
          termsOfServiceURL
          privacyPolicyURL
        }
      }
    }
  }
`;
