import gql from 'graphql-tag';
import {SearchUserType} from '../../../types/PaginatedTypes';

export type SearchUsersSTAQueryResponse = {
  organizationalUnitQuery: {
    organizationalUnit: {
      members: {
        continuationId?: string;
        pageInfo: {
          hasNextPage: boolean;
          startCursor: string;
          endCursor: string;
          totalResults: number;
          nextCursor: string;
          pageSize: number;
          resultsCount: number;
        };
        users: SearchUserType[];
      };
    };
  };
};
export const SEARCH_PAGINATED_USERS_STA = gql`
  query SearchUsersInScopesSTA(
    $organizationalUnit: OrganizationalUnitInput!
    $filters: PaginatedMembersFilter
    $pageInfo: CursorPageInput
  ) {
    organizationalUnitQuery {
      organizationalUnit(organizationalUnit: $organizationalUnit) {
        __typename
        ... on Error {
          __typename
          message
        }

        ... on Organization {
          members(filters: $filters, pageInfo: $pageInfo) {
            __typename
            ...PaginatedMembersFragment
          }
        }
      }
    }
  }

  fragment PaginatedMembersFragment on PaginatedMembersResponse {
    pageInfo {
      hasNextPage
      nextCursor
      startCursor
      endCursor
      totalResults
      resultsCount
    }
    users {
      ...OrganizationMemberFragment
    }
  }

  fragment OrganizationMemberFragment on OrganizationMember {
    id
    firstname: firstName
    lastname: lastName
    memberStatus
    username
    role
    profilePic: avatar {
      ...AttachmentFragment
    }
  }

  fragment AttachmentFragment on Attachment {
    id
    url
    mimeType
    fileName
  }
`;
