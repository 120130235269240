import React from 'react';
import {HCLabelOne} from '../../components/HypercareComponents';
import {NO_SEARCH_RESULT_FOUND_TITLE} from '../../constants/strings';
import CustomSearchField from '../../components/shared/CustomSearchField';
import {CircularProgress} from '@mui/material';
import styled from '@emotion/styled';

export const LoadingSearchContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const UserSearchContainer = styled.div`
  max-height: 400px;
  overflow: auto;
  padding: 8px 0px;
`;

interface Props<TOption> {
  onClear: () => void;
  searchText: string;
  handleSearch: (e) => void;
  isSearchLoading: boolean;
  options: {
    title: string;
    options: TOption[];
  };
  optionRender: (option: TOption) => React.ReactNode;
}

export const HCClickFilter = <TOption,>({
  isSearchLoading,
  handleSearch,
  optionRender,
  searchText,
  options,
  onClear,
}: Props<TOption>) => {
  const renderData = (rows, noRowsString = 'No results found') => {
    return rows.length > 0 ? (
      rows.map((row, i) => (
        <div key={i} style={{display: 'flex', alignItems: 'center'}}>
          {optionRender ? optionRender(row) : row.label}
        </div>
      ))
    ) : (
      <div>{noRowsString}</div>
    );
  };

  const dropdownResults = () => {
    if (searchText.length === 0) {
      return null;
    }

    if (searchText.length > 0 && options.options.filter((opt) => options.options.length > 0).length <= 0) {
      return <div>{NO_SEARCH_RESULT_FOUND_TITLE}</div>;
    }

    return (
      <div>
        <div style={{marginTop: 0}}>
          {options.options.length === 0 ? (
            <div style={{paddingBottom: 10}}>
              <HCLabelOne>{NO_SEARCH_RESULT_FOUND_TITLE}</HCLabelOne>
            </div>
          ) : (
            renderData(options.options)
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <CustomSearchField
        clearSearchText={onClear}
        handleChange={(e) => handleSearch(e.target.value)}
        localSearchText={searchText}
        placeHolder={'Enter at least two characters to search'}
        inputWidth={'600px'}
        inputHeight={'40px'}
      />
      {isSearchLoading ? (
        <LoadingSearchContainer>
          <CircularProgress />
        </LoadingSearchContainer>
      ) : (
        <UserSearchContainer>{dropdownResults()}</UserSearchContainer>
      )}
    </>
  );
};
