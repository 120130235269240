import React, {useState, useCallback, useEffect} from 'react';
import {ValueType} from 'react-select/src/types';
import styled from '@emotion/styled';
import {SiteScope, typedUseSelector, UserSelectOptionType} from 'src/types';
import AnalyticsManager, {PAGE_VIEWS} from 'src/analytics/AnalyticsManager';
import {UserViewModel} from 'src/pages/ContactsPage/viewModels/UserViewModel';
import debounce from 'lodash/debounce';
import {UserAccordionText} from '../new-chat-layout/UserAccordionText';
import {usePaginatedDataState} from 'src/pages/ContactsPage/ContactListSection/hooks/usePaginatedDataState';
import {SiteScopes} from 'src/data/repository/UserRepository';
import {MultiUserInputUser, PaginatedUsersType} from 'src/types/PaginatedTypes';
import HypercareSchedulingViewModel from '../../../LocatingPage/viewModels/HypercareSchedulingViewModel';

const StyledDropdownWrapper = styled.div`
  flex-grow: 1;
  .Mui-focused {
    color: black !important;
  }
`;

const ChatInformationUserDropDownSTA = ({
  chat,
  onClickColleague,
  searchText,
  setSearchText,
  selectedColleague,
  setSelectedColleague,
}) => {
  const [options, setOptions] = useState<PaginatedUsersType[] | []>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [continuationId, setContinuationId] = useState<string | null>(null);
  const [selectedSitesIds, setSelectedSitesIds] = React.useState<number[]>([]);
  const [siteFullScope, setSiteFullScope] = React.useState<SiteScope[]>([]);
  const {isDoneRows, setIsDoneRows} = usePaginatedDataState();
  const organizationId = typedUseSelector((state) => state.organization.organizationId);
  const {getSearchPaginatedUsersInScope} = UserViewModel();

  useEffect(() => {
    AnalyticsManager.recordPageVisited(PAGE_VIEWS.selectContactsList);
  }, []);

  function handleChangeMulti(value: ValueType<UserSelectOptionType>) {
    onClickColleague(value);
    setSelectedColleague(value);
  }

  const {getSitesForOrganization} = HypercareSchedulingViewModel();

  useEffect(() => {
    const getSitesForOrg = async () => {
      const result = await getSitesForOrganization(organizationId || 0, false);

      if (result?.siteFullScope) {
        setSiteFullScope(result.siteFullScope.map((s) => ({...s, isAdmin: false, departments: [], image: ''})));
      } else {
        setSiteFullScope([]);
      }
    };

    getSitesForOrg();
  }, []);

  const siteOptions = React.useMemo(() => {
    return siteFullScope.map((site) => ({
      id: site.id,
      label: site.name,
      value: site,
    }));
  }, [siteFullScope]);

  const sitesScope = React.useMemo(() => {
    return selectedSitesIds.map((siteId) => ({
      id: siteId,
      type: 'site',
    }));
  }, [selectedSitesIds]);

  useEffect(() => {
    if (sitesScope && searchText.length > 0) {
      setIsLoading(true);
      handleCombinedSearch(searchText, sitesScope);
    }
  }, [sitesScope]);

  const loadOptions = useCallback(
    async (searchTextLocal?: string, cursor?: string) => {
      const cursorValue = cursor || continuationId;
      const searchValue = typeof searchTextLocal !== 'number' ? searchTextLocal : searchText;
      if (isLoading) {
        return;
      }
      if (isDoneRows || !cursorValue) {
        return;
      }

      try {
        const response = await getSearchPaginatedUsersInScope({
          text: searchValue,
          scopes: sitesScope,
          pageInfo: {
            cursor: cursorValue,
            direction: 'next',
            pageSize: 30,
          },
        });
        if (response.error !== undefined) {
          throw new Error();
        }
        response.users = response.users.filter((user) => user.memberStatus !== 'inactive');
        const newOptions = response.users;
        if (response?.users?.length > 0) {
          const uniqueNewOptions = newOptions.filter((newOption) => {
            return ![...options].some((existingOption) => existingOption.id === newOption.id);
          });
          setOptions((prevOptions) => {
            const updatedOptions = [...prevOptions, ...uniqueNewOptions];
            if (updatedOptions.length < 9) {
              loadOptions(searchTextLocal, response.pageInfo.nextCursor);
            }
            return updatedOptions;
          });
          setContinuationId(response.pageInfo.nextCursor);
          setIsDoneRows(!response.pageInfo.hasNextPage);
        } else {
          setIsDoneRows(true);
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    },
    [isLoading, continuationId, searchText],
  );

  const getSearchData = async (searchValue: string, scopes: SiteScopes[]) => {
    const searchResult = await getSearchPaginatedUsersInScope({
      text: searchValue,
      scopes,
      pageInfo: {
        cursor: null,
        direction: 'next',
        pageSize: 30,
      },
    });

    if ('error' in searchResult) {
      setOptions([]);
    } else {
      searchResult.users = searchResult.users.filter((user) => user.memberStatus !== 'inactive');
      setOptions(searchResult.users);
      setContinuationId(searchResult.pageInfo.nextCursor);
      setIsDoneRows(!searchResult.pageInfo.hasNextPage);
      if (searchResult.users.length < 10) {
        loadOptions(searchValue, searchResult.pageInfo.nextCursor);
      }
    }

    setIsLoading(false);
  };

  const handleCombinedSearch = React.useCallback(
    debounce(async (searchValue: string, scopes: SiteScopes[]) => {
      await getSearchData(searchValue, scopes);
    }, 250),
    [],
  );

  const handleSearch = async (searchValue: string) => {
    setSearchText(searchValue);

    if (searchValue === '') {
      setOptions([]);
      return;
    }

    if (searchValue.length <= 1) {
      return;
    }

    setIsLoading(true);

    handleCombinedSearch(searchValue, sitesScope);
  };

  const getFilteredColleagues = () => {
    const colleaguesNotInChat = options?.filter((colleague) => {
      return !chat.members.find((member) => colleague.id === member.id);
    });
    return colleaguesNotInChat;
  };
  const filteredColleagues = getFilteredColleagues();

  const suggestions = filteredColleagues
    ? Array.from(filteredColleagues).map((colleague) => {
        return {
          id: colleague?.id,
          value: colleague?.id,
          label: colleague?.firstname + ' ' + colleague?.lastname,
          role: colleague?.role,
          profilePic: colleague?.profilePic,
          username: colleague?.username,
        } as MultiUserInputUser;
      })
    : [];

  return (
    <StyledDropdownWrapper onClick={(e) => e.stopPropagation()}>
      <UserAccordionText
        userOptions={suggestions}
        searchText={searchText}
        handleSearch={handleSearch}
        selectedUsers={selectedColleague}
        setSelectedUsers={setSelectedColleague}
        setReduxSelectedColleagues={handleChangeMulti}
        setLocalSearchText={setSearchText}
        fetchMorePaginatedSearchData={loadOptions}
        isDoneRows={isDoneRows}
        isSearchLoading={isLoading}
        selectedSitesIds={selectedSitesIds}
        setSelectedSitesIds={setSelectedSitesIds}
        siteOptions={siteOptions}
      />
    </StyledDropdownWrapper>
  );
};

export default ChatInformationUserDropDownSTA;
