import {BasicUser} from '../../types/sta';
import {AuthPayloadDTO, OrganizationLoginMethods} from '../../types';

export const checkIfUserHasLoginScope = (user: BasicUser, scope: OrganizationLoginMethods) => {
  let userLoginMethods = user.loginMethods;
  return userLoginMethods.includes(scope);
};

export const checkIfUserIsSignedIn = (savedOrg: AuthPayloadDTO | null) => {
  if (!savedOrg) return false;
  return !!(savedOrg.accessToken && savedOrg.refreshToken);
};
