import {Account, OrganizationAccountsCacheData, UpdateAccountInformationWithRefreshTokenData} from '../../types/sta';
import {localStorageService} from '../../services/localStorageService';
import {AUTH_INFO, MOBILE_CLIENT_ID, MOBILE_DEVICE_ID, ORGANIZATION_ACCOUNTS_DATA} from '../../constants/storageKeys';
import {AuthPayload, AuthPayloadDTO} from '../../types';
import {ADMIN_WEB_APP_PROD_URL} from '../../constants/strings';
import {gzip, inflate} from 'pako';
import {getParsedAuthRegion, getParsedSessionID} from '../localStorageHandler';

export const getCurrentLoggedInAccount = () => {
  const authInfo = localStorageService.getItem<AuthPayload>(AUTH_INFO);
  const cacheData = localStorageService.getItem<OrganizationAccountsCacheData>(ORGANIZATION_ACCOUNTS_DATA);

  return cacheData?.savedOrganizations.find(
    (account) => account.user.id === cacheData?.selectedAccountUserId && authInfo?.user.id === account.user.id,
  );
};

export const logoutCurrentAccount = () => {
  const currentAccount = getCurrentLoggedInAccount();

  if (currentAccount?.user?.id) {
    updateAccountInformation(currentAccount?.user.id, {
      accessToken: null,
      refreshToken: null,
      accessTokenExpiresAt: null,
    });
  }
};

export const getAllLoggedInAccounts = () => {
  const cacheData = localStorageService.getItem<OrganizationAccountsCacheData>(ORGANIZATION_ACCOUNTS_DATA);
  return cacheData?.savedOrganizations.filter((account) => account.accessToken) || [];
};

export const getOrganizationFromCache = (userId: string) => {
  const cachedData = localStorageService.getItem<OrganizationAccountsCacheData>(ORGANIZATION_ACCOUNTS_DATA);
  return cachedData?.savedOrganizations.find((account) => account.user.id === userId);
};

export const checkForLoggedOutAccounts = (cachedData: OrganizationAccountsCacheData | null) => {
  if (!cachedData) {
    return false;
  }
  return cachedData?.savedOrganizations.some((account) => !account.accessToken);
};

export const isRefreshTokenValid = (account: AuthPayloadDTO) => {
  return !!account.refreshToken;
};

export const isAccessTokenValid = (account: AuthPayloadDTO) => {
  if (!account.accessToken) return false;

  if (!account.accessTokenExpiresAt) return false;

  let accessTokenExpiry = new Date(account.accessTokenExpiresAt);

  let now = new Date();

  return accessTokenExpiry > now;
};

export const SAVED_ACCOUNTS_COOKIE = 'savedAccounts';

export const THIRTY_DAYS = 30 * 24 * 60 * 60 * 1000;
export const HYPERCARE_DOMAIN = '.hypercare.com';

export interface CookieOptions {
  secure?: boolean;
  crossSite?: boolean;
  partitioned?: boolean;
  domain?: string;
}

export function setCookie(name: string, value: string, expireDelay: number, options?: CookieOptions) {
  const date = new Date();
  date.setTime(date.getTime() + expireDelay);
  const expires = `expires=${date.toUTCString()}`;
  const sameSite = options && options.crossSite ? 'none' : 'strict';
  const domain = options && options.domain ? `;domain=${options.domain}` : '';
  const secure = options && options.secure ? ';secure' : '';
  const partitioned = options && options.partitioned ? ';partitioned' : '';
  document.cookie = `${name}=${value};${expires};path=/;samesite=${sameSite}${domain}${secure}${partitioned}`;
}

export const getAuthClientId = () => {
  const mobileClientId = localStorageService.getItem<string>(MOBILE_CLIENT_ID);

  return mobileClientId || `${process.env.REACT_APP_AUTH_CLIENT_ID}`;
};

export const getAuthDeviceID = () => {
  const mobileDeviceId = localStorageService.getItem<string>(MOBILE_DEVICE_ID);

  return mobileDeviceId || getParsedSessionID();
};

export const decodeBase64String = (base64EncodedString: string) => {
  if (!base64EncodedString) {
    return '';
  }
  try {
    const encodedData = window.atob(base64EncodedString);
    return JSON.parse(encodedData);
  } catch (e) {
    console.error('Error decoding string:', e);
    return '';
  }
};

export const getAdminWebAppUrl = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'production':
      return ADMIN_WEB_APP_PROD_URL;
    case 'staging':
      return 'https://admin.app.release.hypercare.com';
    default:
      return ADMIN_WEB_APP_PROD_URL;
  }
};

export const gzipBase64 = (input) => {
  // Convert string to a Uint8Array
  const textEncoder = new TextEncoder();
  const encodedData = textEncoder.encode(input);

  // Gzip the encoded data
  const gzippedData = gzip(encodedData);

  // Convert gzipped data to base64
  const base64String = btoa(String.fromCharCode(...gzippedData));

  return base64String;
};

export const base64GzipDecode = (compressedBase64) => {
  // Convert the base64 string back to gzipped data (a Uint8Array)
  const binaryString = atob(compressedBase64);
  const charData = binaryString.split('').map((char) => char.charCodeAt(0));
  const gzippedData = new Uint8Array(charData);

  // Decompress the gzipped data
  const decompressedData = inflate(gzippedData);

  // Convert the decompressed data back to a string
  const textDecoder = new TextDecoder();
  const originalString = textDecoder.decode(decompressedData);

  return originalString;
};

export const logoutAllSavedAccounts = ({savedOrganizations}: OrganizationAccountsCacheData) => {
  const updatedOrganizations = savedOrganizations.map(({accessToken, ...rest}) => ({
    ...rest,
    accessToken: null,
    refreshToken: null,
    accessTokenExpiresAt: null,
  }));

  localStorageService.setItem(ORGANIZATION_ACCOUNTS_DATA, {
    selectedAccountUserId: '',
    savedOrganizations: updatedOrganizations,
  });

  return updatedOrganizations;
};

export const sortSavedAccounts = (savedAccounts: AuthPayloadDTO[]): AuthPayloadDTO[] => {
  if (savedAccounts.length === 0) {
    return [];
  }
  return savedAccounts.sort((a, b) => {
    const orgComparison = a.organization.name.localeCompare(b.organization.name);
    if (orgComparison !== 0) return orgComparison;

    const lastNameComparison = a.user.lastname?.localeCompare(b.user.lastname);
    if (lastNameComparison !== 0) return lastNameComparison;

    return a.user.firstname.localeCompare(b.user.firstname);
  });
};

export const sortOrganizationsByName = (org: Account[]) => {
  if (!org) {
    return [];
  }
  return org.sort((a, b) => a.organization.name.localeCompare(b.organization.name));
};

export const createOrganizationData = (account: Account) => {
  return {
    accessToken: null,
    accessTokenExpiresAt: null,
    refreshToken: null,
    organization: account.organization,
    refreshTokenExpiresAt: null,
    scopeToken: '',
    sessionID: '',
    user: {
      id: account.id,
      firstname: account.firstName,
      lastname: account.lastName,
      email: account.email,
      isAdmin: account.isAdmin || false,
      loginMethods: account.loginMethods || [],
    },
  };
};

export const saveNotLoggedInAccountToCache = (incomingAccount: Account) => {
  let cacheData = getCacheData();

  const newOrganizationData = createOrganizationData(incomingAccount);
  cacheData.savedOrganizations.unshift(newOrganizationData);
  cacheData.regionCode = getParsedAuthRegion();

  updateCacheData(cacheData);
};

export const getCacheData = (): OrganizationAccountsCacheData => {
  return (
    localStorageService.getItem<OrganizationAccountsCacheData>(ORGANIZATION_ACCOUNTS_DATA) ?? {
      savedOrganizations: [],
      selectedAccountUserId: '',
    }
  );
};

export const updateCacheData = (cacheData: OrganizationAccountsCacheData) => {
  window.localStorage.setItem(ORGANIZATION_ACCOUNTS_DATA, JSON.stringify(cacheData));
};

export const getAccountFromCache = (userId: string) => {
  let cacheData = getCacheData();

  if (!cacheData) {
    return false;
  }
  return cacheData?.savedOrganizations.find((account) => account.user.id === userId);
};

export const removeCurrentSelectedAcc = () => {
  let cacheData = getCacheData();
  if (!cacheData) {
    return false;
  }

  cacheData.selectedAccountUserId = '';

  updateCacheData(cacheData);
};

export const updateAccountInformation = (userId: string, payload: UpdateAccountInformationWithRefreshTokenData) => {
  let savedCacheData: OrganizationAccountsCacheData = localStorageService.getItem(ORGANIZATION_ACCOUNTS_DATA) ?? {
    savedOrganizations: [],
    selectedAccountUserId: '',
  };

  const existingAccountIndex = savedCacheData.savedOrganizations.findIndex((org) => org.user.id === userId);

  if (existingAccountIndex !== -1) {
    let [existingOrgToModify] = savedCacheData.savedOrganizations.splice(existingAccountIndex, 1);

    savedCacheData.savedOrganizations.unshift({
      ...existingOrgToModify,
      accessToken: payload.accessToken,
      refreshToken: payload.refreshToken,
      accessTokenExpiresAt: payload.accessTokenExpiresAt,
    });
  }

  window.localStorage.setItem(ORGANIZATION_ACCOUNTS_DATA, JSON.stringify(savedCacheData));
};
