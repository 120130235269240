import gql from 'graphql-tag';

export const CANCEL_INVITE_STA = gql`
  mutation CancelInvite($inviteId: Int!) {
    selfMutation {
      invite(id: $inviteId) {
        cancel {
          ... on UserInvite {
            id
            organization {
              ...OrganizationFragment
            }
            user {
              ...OrganizationMemberFragment
            }
            status
          }
        }
      }
    }
  }

  fragment OrganizationFragment on Organization {
    id
    name
  }

  fragment OrganizationMemberFragment on OrganizationMember {
    id
    firstname: firstName
    lastname: lastName
    role
    memberStatus
  }
`;
