import React, {useState} from 'react';
import {PaginatedUsers} from 'src/gql/v2/query/FetchPaginatedUsersQuery';
import {SearchUserType} from '../../../../types/PaginatedTypes';
import {InvitedUser} from '../../../../types';

export interface PaginatedSearch {
  pageInfo: {
    hasNextPage: boolean;
    startCursor: string;
    endCursor: string;
    totalResults: number;
    nextCursor: string;
    pageSize: number;
    resultsCount: number;
  };
  users: SearchUserType[];
}

export interface PaginatedInvite {
  pageInfo: {
    hasNextPage: boolean;
    startCursor: string;
    endCursor: string;
    totalResults: number;
    nextCursor: string;
    pageSize: number;
    resultsCount: number;
  };
  invites: InvitedUser[];
}
export const usePaginatedSearchStateSTA = () => {
  const [searchUserData, setSearchUserData] = useState<PaginatedSearch | null>(null);
  const [searchInviteData, setSearchInviteData] = useState<PaginatedInvite | null>(null);

  const [localSearchText, setLocalSearchText] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);

  return {
    searchUserData,
    setSearchUserData,
    localSearchText,
    searchLoading,
    setLocalSearchText,
    setSearchLoading,
    searchInviteData,
    setSearchInviteData,
  };
};
