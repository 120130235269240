import {AuthHelper} from './index';
import {updateAccountInformation} from '../utils/sta/staUtils';

export const AuthViewModel = () => {
  const handleRefreshAccessToken = async (refreshToken: string, userId: string) => {
    const refreshTokenResponse = await AuthHelper.refreshAccessTokenRequest(refreshToken);

    if (!refreshTokenResponse || refreshTokenResponse.error) {
      updateAccountInformation(userId, {
        accessToken: null,
        refreshToken: null,
        accessTokenExpiresAt: null,
      });
    }

    if (refreshTokenResponse.data) {
      updateAccountInformation(userId, {
        accessToken: refreshTokenResponse.data.access_token,
        refreshToken: refreshTokenResponse.data.refresh_token,
        accessTokenExpiresAt: refreshTokenResponse.data.expires_at,
      });
    }
  };

  return {handleRefreshAccessToken};
};
