import {OrganizationLoginMethods, OrganizationScope} from './Organization';
import {AuthPayloadDTO, AuthRegion} from './Auth';

type UserAccountStatus = 'active' | 'inactive' | 'blocked' | 'archived';

type MemberAccountStatus = 'active' | 'inactive' | 'blocked' | 'archived';

export enum CurrentResetPasswordStep {
  STEP_1 = 1,
  STEP_2,
  STEP_3,
  STEP_4,
  STEP_5,
  STEP_6,
}

export enum CurrentLoginStep {
  STEP_1 = 1,
  STEP_2,
  STEP_3,
  STEP_4,
  STEP_5,
  STEP_6,
}

export enum WebViewCallBacks {
  ACCOUNTS_FETCHED = 'accountsFetched',
  SWITCH_REGION = 'switchRegion',
  REPORT_ERROR = 'reportError',
  DISMISS_WEB_VIEW = 'dismissWebView',
  ON_WEB_VIEW_READY = 'onWebViewReady',
}

export enum OrgLoginMethods {
  PASSWORD = 'password',
  SSO = 'sso',
  OTP = 'otp',
  LOGIN = 'login',
}

export enum OrgViews {
  EMAIL = 'email',
  FIND_MY_ORGANIZATION = 'findMyOrganization',
  ACCOUNT_DISCOVERY = 'accountDiscovery',
  ACCOUNT_SELECTION = 'accountSelectionView',
  OTP = 'otp',
  OPEN_DOOR_ORG_FLOW = 'openDoorOrgFlow',
  SSO = 'sso',
  PASSWORD = 'password',
}

export enum AccountStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  BLOCKED = 'blocked',
  ARCHIVED = 'archived',
}
export interface BasicUser {
  id: string;
  firstName: string;
  lastName: string;
  isAdmin: boolean;
  avatar: {
    url: string;
  };
  memberStatus: MemberAccountStatus;
  loginMethods: OrganizationLoginMethods[];
  ssoProfiles?: {
    organization: {
      name: string;
      url: string;
    };
    auth0Id: string;
    ssoId: string;
    ssoVendor: string;
    domain: string;
  }[];
  __typename: string;
}

export interface ValidateAddressVerificationResponse {
  response: {
    challengeId: string;
    addressType: string;
    status: string;
    address: string;
  };
  errors?: Array<{name: string; message: string}>;
}

export interface ExchangeOTPTokenForPartialAccessTokenResponse {
  access_token: string;
  token_type: string;
  expires_at: string;
  scope: string[];
  identity: {
    __typename: string;
    email: string;
  };
  error?: string;
  error_description?: string;
  reason?: string;
}

export interface TokenExchangeGrantRequestResponse {
  access_token: string;
  expires_at: string;
  identity: {
    __typename: string;
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    eulaStatus: boolean;
    workStatus: string;
  };
  issued_token_type: string;
  refresh_token: string;
  scope: string[];
  token_type: string;
  error?: string;
  error_description?: string;
  reason?: string;
}

export interface OrganizationAccountsCacheDataFromMobile {
  regionCode: string;
  selectedAccountUserId: string;
  selectedOpenDoorOrganization: {
    email: string;
    domainOrganization: DomainOrganization;
  };
  savedOrganizations: AuthPayloadDTO[];
  clientId: string;
  deviceId: string;
}

export interface OrganizationAccountsDataFromAdmin {
  regionCode: string;
  deviceId: string;
  selectedAccount: AuthPayloadDTO;
  clientId: string;
}

export interface OrganizationAccountsCacheData {
  selectedAccountUserId: string;
  savedOrganizations: AuthPayloadDTO[];
  regionCode?: AuthRegion;
}

export interface GetAccountsForEmailResponse {
  data: {
    accounts: {
      accounts: Account[];
    };
  };
}

export interface Account {
  id: string;
  firstName: string;
  lastName: string;
  email?: string;
  memberStatus: string;
  loginMethods: OrgLoginMethods[];
  isAdmin: boolean;
  organization: {
    name: string;
    url: string;
    id: number;
    isOpenDoor: boolean;
    loginMethods: string[];
    termsOfServiceURL: string;
    privacyPolicyURL: string;
    imageURL: string;
  };
  unreadChatCount?: number;
}
export interface ActivateShellAccountUserDetails {
  firstName: string;
  lastName: string;
  role: string;
  password: string;
}

export interface RefreshTokenRequestResponse {
  data?: TokenExchangeGrantRequestResponse;
  error?: string;
}

export type UpdateAccountInformationWithRefreshTokenData = Pick<
  AuthPayloadDTO,
  'accessToken' | 'accessTokenExpiresAt' | 'refreshToken'
>;

export interface DomainOrganization {
  __typename: string;
  name: string;
  imageURL: string;
  url: string;
  isOpenDoor: boolean;
  id: number;
  termsOfServiceURL: string;
  privacyPolicyURL: string;
  loginMethods: OrganizationLoginMethods[];
  domains: string[];
}

export type Address = {
  __typename: string;
  id: string;
  address: string;
  type: string;
  access: string;
  label: string;
};

export interface ErrorModalButtons {
  type: string;
  buttonLabel: string;
  onClickHandler: () => void;
  id: string;
}
