import React from 'react';
import {toast} from 'react-toastify';
import styled from '@emotion/styled';
import {typedUseSelector} from 'src/redux';
import {CONTACTS} from 'src/constants/routerPathName';
import InviteColleaguesModal from 'src/components/InviteColleaguesModal';
import HypercareSelfProfileViewModel from 'src/pages/LocatingPage/viewModels/HypercareSelfProfileViewModel';
import InvitationUserListWrapperV2 from 'src/pages/ContactsPage/InvitedUserSection/InvitationUserListWrapperV2';
import debounce from 'lodash/debounce';
import {UserViewModel} from 'src/pages/ContactsPage/viewModels/UserViewModel';
import ContactsSearchBar from 'src/components/ContactsSearchBar';
import {USERS_PAGE_SEARCH_FIELD_PLACEHOLDER} from 'src/constants/strings';
import {SitesFilter} from 'src/pages/ContactsPage/filters/SitesFilter';
import {SiteScope, UserAddress} from 'src/types';
import {SiteScopes} from 'src/data/repository/UserRepository';
import {IsFeatureFlagEnabled} from 'src/utils/FeatureFlagManager';
import {FeatureFlagResult} from 'src/utils/FeatureFlags';
import {InviteUserResponseModal} from 'src/components/InvitationTabView/views/InviteUserResponseModal';
import {IInviteResponse} from 'src/gql/v2/mutation/InviteUsersMutation';
import HypercareSchedulingViewModel from '../../../LocatingPage/viewModels/HypercareSchedulingViewModel';
import {MinimalSiteData} from '../../../../gql/query/FetchSitesForOrganization';
import {usePaginatedSearchState} from '../../ContactListSection/hooks/usePaginatedSearchState';
import {usePaginatedDataState} from '../../ContactListSection/hooks/usePaginatedDataState';
import TopPanelBanner from '../views/TopPanelBanner';
import FAQSection from '../views/FAQSection';
import InvitationUserListWrapperSTA from '../../InvitedUserSection/InvitationUserListWrapperSTA';
import ContactListWrapperSTA from '../../ContactListSection/sta/ContactListWrapperSTA';
import {CircularProgress} from '@mui/material';
import {CONTACT_LIST_ERROR, NO_USERS} from '../../../../constants/user';
import {usePaginatedSearchStateSTA} from '../../ContactListSection/hooks/usePaginatedSearchStateSTA';
import {usePaginatedPendingDataStateSTA} from '../../InvitedUserList/hooks/usePaginatedPendingDataStateSTA';
import {usePaginatedDataStateSTA} from '../../ContactListSection/hooks/usePaginatedDataStateSTA';

const LayoutWrapper = styled.div`
  height: calc(100vh - 60px - 60px);
  padding: 0 1em;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;
const NoResult = styled.p`
  margin-top: 1em;
  font-size: 16px;
  color: ${(props) => props.theme.colors.greyishBrown};
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const InvitationSearchLayoutSTA = () => {
  const [showInviteColleagueModal, setInviteColleagueModal] = React.useState(false);
  const [selectedSitesIds, setSelectedSitesIds] = React.useState<number[]>([]);
  const [siteFullScope, setSiteFullScope] = React.useState<MinimalSiteData[]>([]);
  const pcSiteFiltersFlagEnabled = IsFeatureFlagEnabled(FeatureFlagResult.pcSiteFilters);
  const organizationId = typedUseSelector((store) => store.organization.organizationId);
  const [showResponseModal, setShowResponseModal] = React.useState(false);
  const [inviteResponse, setInviteResponse] = React.useState<IInviteResponse[]>([]);
  const [addressesInput, setAddressesInput] = React.useState<UserAddress[]>([]);
  const STAFlag = !IsFeatureFlagEnabled(FeatureFlagResult.singleTenantAccountFlag);
  const handleOpenInviteColleagueModal = () => {
    setShowResponseModal(false);
    setInviteColleagueModal(true);
  };

  const handleOpenResponseModal = () => {
    setInviteColleagueModal(false);
  };

  const handleOnCloseResponseModal = () => {
    setShowResponseModal(false);
  };

  const {useGetOrganizationAllowInviteStatus} = HypercareSelfProfileViewModel();

  const {getSitesForOrganization} = HypercareSchedulingViewModel();

  React.useEffect(() => {
    const getSitesForOrg = async () => {
      const result = await getSitesForOrganization(organizationId || 0, false);

      if (result?.siteFullScope) {
        setSiteFullScope(result.siteFullScope);
      } else {
        setSiteFullScope([]);
      }
    };

    getSitesForOrg();
  }, []);

  const siteOptions = React.useMemo(() => {
    return siteFullScope.map((site) => ({
      id: String(site.id),
      label: site.name,
      value: site,
    }));
  }, [siteFullScope]);

  const sitesScope = React.useMemo(() => {
    return selectedSitesIds.map((siteId) => ({
      id: Number(siteId),
      type: 'site',
    }));
  }, [selectedSitesIds]);

  const urlSearch = window.location.search;

  React.useEffect(() => {
    if (organizationId) {
      if (urlSearch.indexOf('?') > -1 && urlSearch.includes('open')) {
        setInviteColleagueModal(true);
        window.history.replaceState({}, document.title, `${window.location.origin}/${CONTACTS}`);
      }
    } else {
      setInviteColleagueModal(false);
      toast.warn(`Please select an organization first in order to invite`);
    }
  }, [organizationId, urlSearch]);

  function handleClickInviteColleagues() {
    if (organizationId) {
      setInviteColleagueModal(true);
    } else {
      toast.warn(`Please select an organization first in order to invite`);
    }
  }

  const {data: organizationAllowInviteStatus} = useGetOrganizationAllowInviteStatus();

  const userViewModel = UserViewModel();

  const {
    setSearchInviteData,
    searchInviteData,
    searchUserData,
    setSearchUserData,
    searchLoading,
    setSearchLoading,
    setLocalSearchText,
    localSearchText,
  } = usePaginatedSearchStateSTA();

  const {isDoneRows, setIsDoneRows, setSeenNextCursors, seenNextCursors} = usePaginatedDataStateSTA();

  React.useEffect(() => {
    if (sitesScope && localSearchText.length > 0) {
      setSearchLoading(true);
      handleUserSearch(localSearchText, sitesScope);
    }
  }, [sitesScope]);

  const handleUserSearch = React.useCallback(
    debounce(async (searchValue: string, scopes: SiteScopes[]) => {
      await fetchUserSearchData(searchValue, scopes);
    }, 250),
    [],
  );

  const paginatedUserDataQuery = userViewModel.getPaginatedUsersInScope({
    pageInfo: {
      cursor: null,
      pageSize: 30,
    },
    scopes: sitesScope,
  });

  console.log(paginatedUserDataQuery);
  const paginatedPendingInvitesSTADataQuery = userViewModel.fetchPaginatedInvitesSTA({
    pageInfo: {
      pageSize: 30,
      cursor: null,
    },
  });

  const pendingInvitesResultListSTA =
    localSearchText.length > 0
      ? searchInviteData?.invites || []
      : paginatedPendingInvitesSTADataQuery?.data?.selfQuery?.invites.invites || [];

  const pendingUserInvitesListCountSTA =
    localSearchText.length > 0
      ? searchInviteData?.pageInfo?.totalResults || 0
      : paginatedPendingInvitesSTADataQuery?.data?.selfQuery?.invites.pageInfo?.totalResults || 0;

  // if (paginatedPendingInvitesSTADataQuery?.loading)
  //   return (
  //     <LoadingContainer>
  //       <CircularProgress />
  //     </LoadingContainer>
  //   );
  // if (!pendingInvitesResultListSTA) return <p>{NO_USERS}</p>;
  // if (paginatedPendingInvitesSTADataQuery?.error) return <p>{CONTACT_LIST_ERROR}</p>;

  const contactResultList =
    localSearchText.length > 0
      ? searchUserData?.users || []
      : paginatedUserDataQuery?.data?.organizationalUnitQuery?.organizationalUnit?.members?.users || [];

  const contactsListCount =
    localSearchText.length > 0
      ? searchUserData?.pageInfo?.totalResults || 0
      : paginatedUserDataQuery?.data?.organizationalUnitQuery?.organizationalUnit?.members?.pageInfo?.totalResults || 0;

  console.log({contactsListCount, contactResultList});

  const handleOnComplete = React.useCallback(() => {
    if (STAFlag) {
      setShowResponseModal(true);
      paginatedUserDataQuery.refetch();
    }
  }, []);

  const fetchUserSearchData = async (searchValue: string, scopes: SiteScopes[]) => {
    const searchResult = await userViewModel.getSearchPaginatedUsersInScope({
      text: searchValue,
      filters: {},
      pageInfo: {cursor: null, pageSize: 30},
      scopes: scopes,
    });

    const ls = await userViewModel.getSearchPaginatedInvites({
      pageInfo: {
        cursor: null,
        pageSize: 30,
      },
      filters: {
        inviteStatus: 'pending_admin_approval',
        searchQuery: searchValue,
      },
    });

    if (searchResult?.error !== undefined) {
      setSearchUserData(null);
      return;
    }

    if (ls?.error !== undefined) {
      setSearchInviteData(null);
      return;
    }

    if (searchResult?.users) {
      setSearchUserData(searchResult);
    }

    if (ls.invites) {
      setSearchInviteData(ls);
    }

    setSearchLoading(false);
  };

  const handleSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedSearchText = e.target.value;
    setSeenNextCursors([]);
    setIsDoneRows(false);
    setLocalSearchText(updatedSearchText);

    if (updatedSearchText === '') {
      setSearchUserData(null);
      return;
    }

    if (updatedSearchText.length <= 1) {
      return;
    }

    setSearchLoading(true);

    handleUserSearch(updatedSearchText, sitesScope);
  };

  const clearSearchText = () => {
    setLocalSearchText('');
    setSearchUserData(null);
    setSeenNextCursors([]);
    setIsDoneRows(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.target.blur();
    }
  };

  return (
    <React.Fragment>
      <TopPanelBanner
        allowInvites={organizationAllowInviteStatus}
        handleClickInviteColleagues={handleClickInviteColleagues}
      />

      <ContactsSearchBar
        clearSearchText={clearSearchText}
        handleKeyDown={handleKeyDown}
        handleChange={handleSearch}
        localSearchText={localSearchText}
        placeHolder={USERS_PAGE_SEARCH_FIELD_PLACEHOLDER}
        inputWidth={'360px'}
      />

      {pcSiteFiltersFlagEnabled && (
        <div style={{marginLeft: '15px'}}>
          <SitesFilter
            selectedSitesIds={selectedSitesIds}
            setSelectedSitesIds={setSelectedSitesIds}
            siteOptions={siteOptions.map((o) => ({...o, id: parseInt(o.id)}))}
            setLocalSearchText={setLocalSearchText}
          />
        </div>
      )}

      <LayoutWrapper>
        <FAQSection
          allowInvites={organizationAllowInviteStatus}
          handleClickInviteColleagues={handleClickInviteColleagues}
        />

        {paginatedPendingInvitesSTADataQuery?.data?.selfQuery?.invites.invites.length !== 0 && (
          <InvitationUserListWrapperSTA
            pendingInviteListCount={pendingUserInvitesListCountSTA || 0}
            pendingInviteList={pendingInvitesResultListSTA}
            pendingInviteListQuery={paginatedPendingInvitesSTADataQuery}
          />
        )}

        <ContactListWrapperSTA
          localSearchText={localSearchText}
          searchLoading={searchLoading}
          searchUserData={searchUserData}
          setSearchUserData={setSearchUserData}
          isDoneRows={isDoneRows}
          setIsDoneRows={setIsDoneRows}
          seenNextCursors={seenNextCursors}
          setSeenNextCursors={setSeenNextCursors}
          scopes={sitesScope}
          isLoadingContactList={paginatedUserDataQuery.loading}
          contactListCount={contactsListCount}
          contactResultList={contactResultList}
          paginatedUserDataQuery={paginatedUserDataQuery}
        />

        {showInviteColleagueModal && (
          <InviteColleaguesModal
            isOpen={showInviteColleagueModal}
            closeModal={handleOpenResponseModal}
            handleOnComplete={handleOnComplete}
            setInviteResponse={setInviteResponse}
            setAddressesInput={setAddressesInput}
          />
        )}

        {STAFlag && showResponseModal && (
          <InviteUserResponseModal
            isOpen={showResponseModal}
            handleOnCloseResponseModal={handleOnCloseResponseModal}
            handleOnClick={handleOpenInviteColleagueModal}
            inviteResponse={inviteResponse}
            addressesInput={addressesInput}
          />
        )}
      </LayoutWrapper>
    </React.Fragment>
  );
};

export default InvitationSearchLayoutSTA;
