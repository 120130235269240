import React from 'react';
import {InvitedUser} from 'src/types';
import client from 'src/apollo';
import {getFullName} from 'src/utils/user';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';
import {CANCEL_INVITE_STA} from 'src/gql/v2/mutation/CancelInviteSTA';

/**
 * A hook to cancel the invited user, preserves the loading state to show loader
 * before calling the fetch invite list again after cancellation of invite to refresh the list.
 */
const useCancelInviteSTA = () => {
  const cancelInviteLoadingRef = React.useRef<any[]>([]);
  const [cancelInviteLoadingList, setCancelInviteLoadingList] = React.useState<string[]>([]);

  const tryCancelInvite = React.useCallback(
    async (invitedUser: InvitedUser) => {
      try {
        let resultName: string;
        cancelInviteLoadingRef.current.push(invitedUser.id);
        setCancelInviteLoadingList([...cancelInviteLoadingRef.current]);

        await client.mutate({
          mutation: CANCEL_INVITE_STA,
          fetchPolicy: 'no-cache',
          variables: {
            inviteId: invitedUser.id,
          },
        });

        AnalyticsManager.applyAnalytics({
          eventName: EVENTS.cancelInviteButtonPressed,
          params: {
            invite_id: invitedUser.id,
          },
        });
        resultName = invitedUser.user.firstname;
        return Promise.resolve(resultName);
      } catch (e) {
        return Promise.reject(e);
      } finally {
        cancelInviteLoadingRef.current = cancelInviteLoadingRef.current.filter((id) => id !== invitedUser.id);
        setCancelInviteLoadingList([...cancelInviteLoadingRef.current]);
      }
    },
    [cancelInviteLoadingRef],
  );

  return {
    cancelInviteLoadingList,
    tryCancelInvite,
  };
};

export default useCancelInviteSTA;
