import React from 'react';
import {
  FetchPaginatedRequestInput,
  FetchPaginatedRequestInputSTA,
  SearchRequestInput,
  UserRepository,
} from 'src/data/repository/UserRepository';
import {SearchRequestInputSTA} from '../../../types';

export const UserViewModel = () => {
  const repo = UserRepository();

  const fetchPaginatedUsers = ({continuationId, direction, scopes}: FetchPaginatedRequestInput) => {
    const result = repo.useFetchPaginatedUsers({continuationId, direction, scopes});
    return {
      ...result,
    };
  };

  const fetchOrganizationTotalUsersCount = () => {
    const result = repo.useFetchOrganizationUserCount();
    return {
      data: result?.data?.organizationalUnitQuery.organizationalUnit?.paginatedMembers.totalResultsCount || 0,
      error: result.error,
      loading: result.loading,
    };
  };

  const fetchPaginatedInvites = ({continuationId, direction}: FetchPaginatedRequestInput) => {
    const result = repo.useFetchPaginatedInvites({continuationId, direction});
    return {
      ...result,
    };
  };

  const fetchPaginatedInvitesSTA = ({pageInfo}: FetchPaginatedRequestInputSTA) => {
    const result = repo.useFetchPaginatedInvitesSTA({pageInfo});
    console.log(result);
    return {
      ...result,
    };
  };

  const getPaginatedUsersInScope = ({scopes, pageInfo}) => {
    const result = repo.useFetchPaginatedUsersInScopeSTA({
      scopes,
      filters: {
        organizationalUnits: scopes,
      },
      pageInfo,
    });

    return {
      ...result,
    };
  };

  const getSearchPaginatedUsersInScope = async ({
    text,
    scopes,
    memberStatus,
    filters,
    pageInfo,
  }: SearchRequestInputSTA) => {
    const result = await repo.searchUserInScopeSTA({
      scopes,
      filters: {
        searchQuery: text,
        organizationalUnits: scopes,
        memberStatus,
      },
      pageInfo,
    });
    if (result?.errors) {
      const error = result?.errors[0].message;
      return {error};
    }

    if (!result?.data) {
      return {error: 'missing data'};
    }

    return {
      ...result.data.organizationalUnitQuery.organizationalUnit.members,
    };
  };

  const getSearchPaginatedInvites = async ({filters, pageInfo}: SearchRequestInputSTA) => {
    const result = await repo.searchUserInvitesSTA({filters, pageInfo});
    if (result?.errors) {
      const error = result?.errors[0].message;
      return {error};
    }

    if (!result?.data) {
      return {error: 'missing data'};
    }

    return {
      ...result.data.selfQuery.invites,
    };
  };

  const getSearchUsers = async ({text, continuationId, limit, options, scopes}: SearchRequestInput) => {
    const result = await repo.searchUsers({text, continuationId, limit, options, scopes});
    if (result?.errors) {
      const error = result?.errors[0].message;
      return {error};
    }

    if (!result?.data) {
      return {error: 'missing data'};
    }

    return {
      ...result.data.searchQuery.searchUsersInScopes,
    };
  };

  const fetchDepartmentUsers = ({departmentId}: {departmentId: number}) => {
    const result = repo.useFetchDepartmentUsers({departmentId});

    return {
      ...result,
    };
  };

  return {
    fetchPaginatedUsers,
    fetchPaginatedInvites,
    fetchPaginatedInvitesSTA,
    getSearchUsers,
    fetchOrganizationTotalUsersCount,
    fetchDepartmentUsers,
    getSearchPaginatedInvites,
    getSearchPaginatedUsersInScope,
    getPaginatedUsersInScope,
  };
};
