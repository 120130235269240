import gql from 'graphql-tag';
import {PaginatedUsersType} from 'src/types/PaginatedTypes';

export type FetchUsersSTAQueryResponse = {
  organizationalUnitQuery: {
    organizationalUnit: {
      members: {
        pageInfo: {
          hasNextPage: boolean;
          startCursor: string;
          endCursor: string;
          totalResults: number;
          nextCursor: string;
          pageSize: number;
          resultsCount: number;
        };
        users: PaginatedUsersType[];
      };
    };
  };
};

export const FETCH_USERS_IN_SCOPE_CURSOR_QUERY = gql`
  query FetchUsersInScope(
    $organizationalUnit: OrganizationalUnitInput!
    $filters: PaginatedMembersFilter
    $pageInfo: CursorPageInput
  ) {
    organizationalUnitQuery {
      organizationalUnit(organizationalUnit: $organizationalUnit) {
        __typename
        ... on Error {
          __typename
          message
        }

        ... on Organization {
          members(filters: $filters, pageInfo: $pageInfo) {
            __typename
            ...PaginatedMembersFragment
          }
        }
      }
    }
  }

  fragment PaginatedMembersFragment on PaginatedMembersResponse {
    pageInfo {
      hasNextPage
      nextCursor
      startCursor
      endCursor
      totalResults
      resultsCount
    }
    users {
      ...OrganizationMemberFragment
    }
  }

  fragment OrganizationMemberFragment on OrganizationMember {
    id
    firstname: firstName
    lastname: lastName
    accountStatus
    memberStatus
    avatar {
      id
      url
    }
    addresses {
      address
      label
      type
      access
    }
    workStatus {
      value
      alternateContact {
        id
        firstname: firstName
        lastname: lastName
      }
      message
    }
  }
`;
