import React, {useContext, useState} from 'react';
import {HCHeadingTwo} from '../../../../components/HypercareComponents';
import {LOGIN_PAGE_FIND_MY_ORG_TITLE} from '../../../../constants/strings';
import {AccountSelectionEditModeTitle, CurrentRegionText} from '../../../../microfrontend/login/SavedAccountsTitle';
import HypercareAuthRegionContext from '../../../../contexts/HypercareLoginCoordinatorContext';
import {getParsedAuthRegion} from '../../../../utils/localStorageHandler';
import {FindUserOrganizationContainer, FlexColumn, PaddedContainer} from '../../styled/login.styled';
import {OrganizationEnterURLView} from './OrganizationEnterURLView';
import {OrganizationSearchView} from './OrganizationSearchView';
import {LoginPageFooterActions} from './LoginPageFooterActions';
import {OrganizationViewModel} from '../view-models/OrganizationViewModel';
import {CurrentSelectedOrganization} from '../../../../microfrontend/types/login.types';
import {muiTheme} from 'src/styles/theme';
import {toast} from 'react-toastify';

export interface HCClickFilterOption {
  imageURL?: string;
  loginMethods: string[];
  name: string;
  url: string;
  id: number;
}

interface FindMyOrganizationViewProps {
  handleGoBack: () => void;
  setCurrentSelectedOrg: React.Dispatch<React.SetStateAction<CurrentSelectedOrganization>>;
  handleShowEmailView: () => void;
  handleShowPasswordView?: () => void;
}

export const FindUserOrganizationView = ({
  handleGoBack,
  setCurrentSelectedOrg,
  handleShowEmailView,
  handleShowPasswordView,
}: FindMyOrganizationViewProps) => {
  const [showOrgUrlView, setShowOrgUrlView] = useState(false);
  const [userOptions, setUserOptions] = useState<HCClickFilterOption[]>([]);
  const [searchText, setSearchText] = useState('');
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const {setChangeRegionModalOpenStatus} = useContext(HypercareAuthRegionContext);
  const currentRegion = getParsedAuthRegion();
  const [orgUrl, setOrgUrl] = useState('');
  const [orgURLError, setOrgURLError] = useState('');
  const {fetchOrganizationByURL, searchOrganizationsByName} = OrganizationViewModel();

  const handleOnUserOptionClick = (option: HCClickFilterOption) => {
    if (!option.url) {
      toast.error('No org url for request org', {toastId: 'NoOrgUrl'});
    }

    console.log(option, 'option');
    if (option.url) {
      setCurrentSelectedOrg(option);
      handleShowEmailView();
    }
  };

  const handleSearch = async (searchValue: string) => {
    setSearchText(searchValue);
    if (searchValue.length <= 1) {
      setUserOptions([]);
      return;
    }

    if (searchValue.length >= 2) {
      const getOrganizationsByName = await searchOrganizationsByName(searchValue);
      const organizations = getOrganizationsByName?.data?.searchOrganizationsByName.organizations;

      if (!organizations) {
        setUserOptions([]);
      } else {
        setUserOptions(organizations);
      }
    }
  };

  const handleOrganizationURLNextButton = async () => {
    if (!orgUrl) return;
    setOrgURLError('');
    const data = await fetchOrganizationByURL(orgUrl);

    if (data?.data?.findOrganizationByURL.url === orgUrl) {
      setCurrentSelectedOrg(data?.data?.findOrganizationByURL);
      handleShowEmailView();
    }

    if (typeof data.error === 'string') {
      setOrgURLError(data.error);
    }
  };

  const clearSearch = () => {
    setSearchText('');
    setUserOptions([]);
  };

  return (
    <FlexColumn>
      <FindUserOrganizationContainer>
        <AccountSelectionEditModeTitle onClick={handleGoBack} />
        <PaddedContainer>
          <HCHeadingTwo color={muiTheme.colors.black}>{LOGIN_PAGE_FIND_MY_ORG_TITLE}</HCHeadingTwo>
          <div style={{padding: '5px 0'}}>
            <CurrentRegionText currentRegion={currentRegion} onClick={() => setChangeRegionModalOpenStatus(true)} />
          </div>
        </PaddedContainer>
        <PaddedContainer>
          {showOrgUrlView ? (
            <OrganizationEnterURLView
              orgUrl={orgUrl}
              setOrgUrl={setOrgUrl}
              handleNextButtonPressed={handleOrganizationURLNextButton}
              orgURLError={orgURLError}
            />
          ) : (
            <OrganizationSearchView
              clearSearch={clearSearch}
              searchText={searchText}
              setSearchText={setSearchText}
              handleSearch={handleSearch}
              isLoadingSearch={isLoadingSearch}
              userOptions={userOptions}
              handleOnUserOptionClick={handleOnUserOptionClick}
            />
          )}
        </PaddedContainer>
      </FindUserOrganizationContainer>
      <LoginPageFooterActions showOrgUrlView={showOrgUrlView} setShowOrgUrlView={setShowOrgUrlView} />
    </FlexColumn>
  );
};
