import React, {useState, useEffect, useCallback, CSSProperties} from 'react';
import {UserViewModel} from 'src/pages/ContactsPage/viewModels/UserViewModel';
import {UserSelectOptionType} from 'src/types';
import {useTheme} from '@mui/material/styles';
import {ValueType} from 'react-select/src/types';
import * as R from 'ramda';
import overriddenComponents, {
  NoUnderlineControl as Control,
  StyledSelect,
} from 'src/pages/MessengerPage/auto-select/ReactSelectMaterialUI';
import {
  CircleOptionV2 as Option,
  AutoHeightMenuListV2 as MenuList,
} from 'src/pages/MessengerPage/auto-select/ReactSelectSharedComponents';
import debounce from 'lodash/debounce';
import {PaginatedUsersType} from 'src/types/PaginatedTypes';
import {SiteScopes} from 'src/data/repository/UserRepository';
import {IsFeatureFlagEnabled} from 'src/utils/FeatureFlagManager';
import {FeatureFlagResult} from 'src/utils/FeatureFlags';

type CreateNewChatInputDropdownV2Props = {
  reduxStoreSelectedColleagues: UserSelectOptionType[];
  setReduxSelectedColleagues: (reduxStoreSelectedColleagues) => void;
  scopes: SiteScopes[];
};

const CreateNewChatInputDropdownSTA = ({
  reduxStoreSelectedColleagues,
  setReduxSelectedColleagues,
  scopes,
}: CreateNewChatInputDropdownV2Props) => {
  const [options, setOptions] = useState<PaginatedUsersType[] | []>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [continuationId, setContinuationId] = useState<string | null>(null);
  const {getSearchPaginatedUsersInScope} = UserViewModel();
  const theme = useTheme();

  const [selectedColleague, setSelectedColleague] = React.useState<ValueType<UserSelectOptionType>>([]);

  // selectedColleage dependent on reduxStoreSelectedColleagues from reducer
  useEffect(() => {
    if (!R.equals(reduxStoreSelectedColleagues, selectedColleague)) {
      setSelectedColleague(reduxStoreSelectedColleagues);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxStoreSelectedColleagues]);

  function handleChangeMulti(value: ValueType<UserSelectOptionType>) {
    if (value || (reduxStoreSelectedColleagues && reduxStoreSelectedColleagues.length > 0)) {
      setReduxSelectedColleagues(value);
    }
  }

  const loadOptions = useCallback(
    async (searchTextLocal?: string, cursor?: string) => {
      const cursorValue = cursor || continuationId;
      const searchValue = typeof searchTextLocal !== 'number' ? searchTextLocal : searchText;

      if (isLoading || !hasMore || !cursorValue) {
        return;
      }

      setIsLoading(true);

      try {
        const response = await getSearchPaginatedUsersInScope({
          text: searchValue,
          scopes,
          pageInfo: {
            cursor: cursorValue,
            direction: 'next',
            pageSize: 30,
          },
        });
        if ('error' in response) {
          setOptions([]);
        } else {
          response.users = response.users.filter((user) => user.memberStatus !== 'inactive');
          const newOptions = response.users;
          if (response?.users?.length > 0) {
            const uniqueNewOptions = newOptions?.filter((newOption) => {
              return ![...options].some((existingOption) => existingOption.id === newOption.id);
            });
            setOptions((prevOptions) => {
              const updatedOptions = [...prevOptions, ...uniqueNewOptions];
              if (updatedOptions.length < 9) {
                loadOptions(searchTextLocal, response.pageInfo.nextCursor);
              }
              return updatedOptions;
            });
            setContinuationId(response.pageInfo.nextCursor);
          } else {
            setHasMore(false);
          }
        }
      } catch (error) {
        console.error('Error loading options:', error);
      } finally {
        setIsLoading(false);
      }
    },
    [isLoading, continuationId, searchText],
  );

  const getSearchData = async (searchValue: string, scopes: SiteScopes[]) => {
    const searchResult = await getSearchPaginatedUsersInScope({
      text: searchValue,
      scopes,
      pageInfo: {
        cursor: null,
        direction: 'next',
        pageSize: 30,
      },
    });
    if ('error' in searchResult) {
      setOptions([]);
    } else {
      searchResult.users = searchResult.users.filter((user) => user.memberStatus !== 'inactive');
      setOptions(searchResult.users);
      if (searchResult.users.length < 5) {
        loadOptions(searchValue, searchResult.pageInfo?.nextCursor);
      }
      setHasMore(searchResult.pageInfo.hasNextPage);
      setContinuationId(searchResult.pageInfo.nextCursor);
    }

    setIsLoading(false);
  };

  const handleCombinedSearch = React.useCallback(
    debounce(async (searchValue: string, scopes: SiteScopes[]) => {
      await getSearchData(searchValue, scopes);
    }, 250),
    [],
  );

  const handleSearch = async (searchValue: string) => {
    setSearchText(searchValue);

    if (searchValue === '') {
      setOptions([]);
      return;
    }

    if (searchValue.length <= 1) {
      return;
    }

    setIsLoading(true);

    handleCombinedSearch(searchValue, scopes);
  };

  const handleInputChange = (inputValue) => {
    setHasMore(true);
    if (!inputValue) {
      setOptions([]);
      setContinuationId(null);
      setHasMore(true);
    }
    setSearchText(inputValue);
    if (inputValue.length > 1) {
      handleSearch(inputValue);
    }
  };

  const suggestions = options
    ? Array.from(options).map((colleague) => {
        return {
          value: colleague?.id,
          label: colleague?.firstname + ' ' + colleague?.lastname,
          firstName: colleague?.firstname,
          lastName: colleague?.lastname,
          role: colleague?.role,
          profilePic: colleague?.profilePic,
        } as UserSelectOptionType;
      })
    : [];

  const handleMenuScrollToBottom = () => {
    if (hasMore) {
      loadOptions(searchText, continuationId!);
    }
  };

  const selectStyles = {
    input: (base: CSSProperties) => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      display: 'none',
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: 'none',
    }),
  };

  return (
    <div style={{flexGrow: 1}} onClick={(e) => e.stopPropagation()}>
      <StyledSelect
        options={suggestions}
        isLoading={isLoading}
        onInputChange={handleInputChange}
        onMenuScrollToBottom={handleMenuScrollToBottom}
        autoFocus
        styles={selectStyles}
        inputId="new-chat-dropdown"
        TextFieldProps={{
          InputLabelProps: {
            htmlFor: 'new-chat-dropdown',
            shrink: true,
          },
        }}
        placeholder={''}
        components={{
          ...overriddenComponents,
          Option,
          Control,
          MenuList,
        }}
        value={selectedColleague}
        filterOption={null}
        openMenuOnClick={true}
        hideSelectedOptions={false}
        closeMenuOnSelect={true}
        onChange={handleChangeMulti}
        isMulti
      />
    </div>
  );
};

export default CreateNewChatInputDropdownSTA;
