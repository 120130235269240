import {getParsedSessionID, trySetSessionID} from './localStorageHandler';
import ApiHelper from 'src/api';

export const registerPushToken = async (token: string, accessToken: string) => {
  let payload = {
    deviceType: 'web',
    token: token,
    pushService: 'fcm',
    environment: process.env.REACT_APP_FIREBASE_ENV || 'sandbox',
  };
  const result = await ApiHelper.PrivateEndpoints.registerPushNotification(payload, accessToken);
  if (result && result.success) {
    const secret = result?.data?.selfMutation?.registerPushToken?.secret;
    if (secret) sessionStorage.setItem('Secret', secret);
    return secret;
  } else {
    console.error(result.error?.response, 'err response');
  }
};

export const unRegisterPushToken = async () => {
  let payload = {
    deviceId: getParsedSessionID(),
  };
  const result = await ApiHelper.PrivateEndpoints.unRegisterPushNotification(payload);
  if (result.error) {
    console.error(result.error.response);
  }
};

export const ackPushNotification = async (notificationId: string, accessToken) => {
  let payload = {
    notificationId: notificationId,
  };
  const result = await ApiHelper.PrivateEndpoints.acknowledgePushNotificationReceived(payload, accessToken);
  console.log(result);
  if (result.error) {
    console.error(result.error);
  }
};

export const messagesDelivered = async (messageIds: string[], accessToken) => {
  let payload = {
    messageIds: messageIds,
  };
  const result = await ApiHelper.PrivateEndpoints.messagesDeliveredReceipt(payload, accessToken);
  if (result.error) {
    console.error(result.error);
  }
};
